import React, { useState, useEffect, useRef } from "react";
import "./FilterModal.css";

const FilterModal = ({ column, items, onClose, onApply, selectedValues }) => {
  const [uniqueValues, setUniqueValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSelectedValues, setCurrentSelectedValues] =
    useState(selectedValues);
  const modalRef = useRef(null);

  useEffect(() => {
    const values = items
      .map((item) => item[column] || item.specs[column])
      .filter((value, index, self) => value && self.indexOf(value) === index);
    setUniqueValues(values);
  }, [column, items]);

  const handleSelect = (value) => {
    setCurrentSelectedValues((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value]
    );
  };

  const handleSelectAll = () => {
    const filteredValues = uniqueValues.filter((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setCurrentSelectedValues(filteredValues);
  };

  const handleClear = () => {
    setCurrentSelectedValues([]);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleApplyAndClose = () => {
    onApply(column, currentSelectedValues);
  };
  const formatColumnName = (key) => {
    return key
      .split("_")
      .join(" ")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  };
  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal-content" ref={modalRef}>
        <h3 className="filter-modal-heading">
          Filter by {formatColumnName(column)}
        </h3>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="filter-search"
        />
        <div className="select-options">
          <p className="select-action-button" onClick={handleSelectAll}>
            Select All ({uniqueValues.length})
          </p>
          <p className="select-action-button" onClick={handleClear}>
            Clear
          </p>
        </div>
        <div className="filter-values">
          {uniqueValues
            .filter((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((value) => (
              <div key={value} className="filter-value">
                <input
                  type="checkbox"
                  checked={currentSelectedValues.includes(value)}
                  onChange={() => handleSelect(value)}
                />
                <label className="checkbox-label">{value.toString()}</label>
              </div>
            ))}
        </div>
        <div className="filter-modal-actions">
          <button
            className="filter-modal-action-button"
            onClick={handleApplyAndClose}
          >
            Apply & Close
          </button>
          <button className="filter-modal-action-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
