// AuthModal.js
import React, { useEffect } from "react";
import "../Auth/AuthModal.css";

const AuthModal = ({ message, onClose, timeout = 5000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, timeout);

    return () => clearTimeout(timer);
  }, [onClose, timeout]);

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal-content">
        <p>{message}</p>
        <div className="auth-timer-bar"></div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default AuthModal;
