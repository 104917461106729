import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Card.css";
import { backendURL } from "../../config";

const PCBStep = ({
  onNext,
  onPrev,
  updateFormData,
  selectedPCB = [],
  selectedClass,
}) => {
  const [filters, setFilters] = useState({
    cct: "",
    cri: "",
    lumensMin: "",
    lumensMax: "",
  });

  const [filteredPCBs, setFilteredPCBs] = useState([]);
  const [localSelectedPCBs, setLocalSelectedPCBs] = useState(selectedPCB || []);

  const [classFilteredPCBs, setClassFilteredPCBs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // State to hold unique filter options
  const [criOptions, setCriOptions] = useState([]);
  const [cctOptions, setCctOptions] = useState([]);
  const [lumensRange, setLumensRange] = useState({ min: 0, max: 12000 });
  const [currentPage, setCurrentPage] = useState(1);
  const pcbsPerPage = 24;

  useEffect(() => {
    // Fetch data from the backend API
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/modules`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const classFiltered = data.filter((pcb) =>
          selectedClass ? pcb.part_name.startsWith(selectedClass) : true
        );
        const criValues = Array.from(
          new Set(classFiltered.map((pcb) => pcb.specs.CRI))
        ).sort((a, b) => a - b);
        const cctValues = Array.from(
          new Set(classFiltered.map((pcb) => pcb.specs.CCT.replace("K", "")))
        ).sort((a, b) => a - b);
        setCriOptions(criValues);
        setCctOptions(cctValues);

        setClassFilteredPCBs(classFiltered);
        setFilteredPCBs(classFiltered);
        updateDynamicRanges(classFiltered);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedClass]);

  useEffect(() => {
    // Filter PCBs based on the filters
    const filtered = classFilteredPCBs.filter((pcb) => {
      const lumens = parseInt(pcb.specs.Lumens, 10);
      const cct = parseInt(pcb.specs.CCT.replace("K", ""), 10);
      const cri = parseInt(pcb.specs.CRI, 10);
      const lumensInRange =
        (!filters.lumensMin || lumens >= parseInt(filters.lumensMin, 10)) &&
        (!filters.lumensMax || lumens <= parseInt(filters.lumensMax, 10));
      const cctMatch = !filters.cct || cct === parseInt(filters.cct, 10);
      const criMatch = !filters.cri || cri === parseInt(filters.cri, 10);
      return criMatch && lumensInRange && cctMatch;
    });
    setFilteredPCBs(filtered);
    updateDynamicRanges(filtered);
  }, [filters, classFilteredPCBs]);

  const updateDynamicRanges = (pcbs) => {
    if (pcbs.length === 0) {
      setLumensRange({ min: 0, max: 10000 });
      return;
    }
    const lumensValues = pcbs.map((pcb) => parseInt(pcb.specs.Lumens, 10));
    setLumensRange({
      min: Math.min(...lumensValues),
      max: Math.max(...lumensValues),
    });
  };

  const handleNext = () => {
    const selectedPcbData = localSelectedPCBs.map((pcbId) => {
      const pcb = classFilteredPCBs.find((pcb) => pcb.id === pcbId);
      console.log("selected PCB specs: ", pcb.specs);
      return {
        ...pcb,
        lensesOptions: pcb.specs["Lenses Options"]
          ? pcb.specs["Lenses Options"].split(", ")
          : [],
      };
    });
    console.log(
      "Selected PCB data to be passed to AccessoryStep: ",
      selectedPcbData
    );
    updateFormData("module", selectedPcbData);
    onNext();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleCardClick = (pcbId) => {
    setLocalSelectedPCBs((prevSelectedPCBs) => {
      if (prevSelectedPCBs.includes(pcbId)) {
        return prevSelectedPCBs.filter((id) => id !== pcbId);
      } else {
        // Reset dependent form data when changing the PCB
        updateFormData("driver", null);
        updateFormData("accessories", {
          lens: null,
          heatSink: null,
          connector: null,
        });
        return [...prevSelectedPCBs, pcbId];
      }
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="loading-spinner"></div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Get current PCBs
  const indexOfLastPCB = currentPage * pcbsPerPage;
  const indexOfFirstPCB = indexOfLastPCB - pcbsPerPage;
  const currentPCBs = filteredPCBs.slice(indexOfFirstPCB, indexOfLastPCB);

  return (
    <div className="step-container">
      <h2 className="module-title">Select Module</h2>
      <div className="filters">
        <label>
          CRI:
          <select name="cri" value={filters.cri} onChange={handleFilterChange}>
            <option value="">All</option>
            {criOptions.map((cri) => (
              <option key={cri} value={cri}>
                {cri}
              </option>
            ))}
          </select>
        </label>
        <label>
          CCT:
          <select name="cct" value={filters.cct} onChange={handleFilterChange}>
            <option value="">All</option>
            {cctOptions.map((cct) => (
              <option key={cct} value={cct}>
                {cct}K
              </option>
            ))}
          </select>
        </label>
        <label>
          Lumens Min:
          <input
            type="number"
            name="lumensMin"
            value={filters.lumensMin}
            onChange={handleFilterChange}
            placeholder={`Min Lumens (e.g. ${lumensRange.min} lm)`}
            step="100"
          />
        </label>
        <label>
          Lumens Max:
          <input
            type="number"
            name="lumensMax"
            value={filters.lumensMax}
            onChange={handleFilterChange}
            placeholder={`Max Lumens (e.g. ${lumensRange.max} lm)`}
            step="100"
          />
        </label>
      </div>
      <div className="results-info">
        {filteredPCBs.length > 0 ? (
          <p>
            Showing {filteredPCBs.length} out of {classFilteredPCBs.length} PCBs
          </p>
        ) : (
          <p>No results found</p>
        )}
        <p>Selected: {localSelectedPCBs.length}</p>
      </div>
      <div className="card-container">
        {currentPCBs.map((pcb) => {
          const specs = [
            `${pcb.specs.Shape}`,
            `${pcb.specs.CCT}`,
            `${pcb.specs.Lumens} lm`,
          ].join(" | ");
          const allSpecs = [
            `Supplier: ${pcb.specs.Supplier}`,
            `Shape: ${pcb.specs.Shape}`,
            `CRI: ${pcb.specs.CRI}`,
            `CCT: ${pcb.specs.CCT}`,
            `Lumens: ${pcb.specs.Lumens} lm`,
          ].join(", ");
          return (
            <Card
              key={pcb.id}
              image={pcb.image}
              name={pcb.part_name}
              specs={allSpecs}
              topSpecs={specs}
              isSelected={localSelectedPCBs.includes(pcb.id)}
              onClick={() => handleCardClick(pcb.id)}
            />
          );
        })}
      </div>
      <div className="pagination">
        {Array.from({
          length: Math.ceil(filteredPCBs.length / pcbsPerPage),
        }).map((_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="navigation-buttons">
        <button className="nav-button-prev" onClick={onPrev}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          className="nav-button-next"
          onClick={handleNext}
          disabled={localSelectedPCBs.length === 0}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default PCBStep;
