// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-edit-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.bulk-edit-modal h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  font-family: "Fivo Sans Medium", sans-serif;
}
.bulk-edit-modal-input-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 15px;
}
.bulk-edit-modal select,
.bulk-edit-modal input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Fivo Sans Light", sans-serif;
}

.bulk-edit-buttons {
  display: flex;
  justify-content: space-between;
}

.bulk-edit-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #b92328;
  color: white;
  cursor: pointer;
  font-family: "Fivo Sans Medium", sans-serif;
}

.bulk-edit-buttons button:hover {
  background-color: #b51f25;
}
`, "",{"version":3,"sources":["webpack://./src/components/BrowseCollection/BulkEditModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,2CAA2C;AAC7C;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,mBAAmB;AACrB;AACA;;EAEE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,2CAA2C;AAC7C;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".bulk-edit-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.bulk-edit-modal {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  width: fit-content;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.bulk-edit-modal h2 {\n  margin-bottom: 20px;\n  font-size: 1.5em;\n  font-family: \"Fivo Sans Medium\", sans-serif;\n}\n.bulk-edit-modal-input-container {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  margin-bottom: 15px;\n}\n.bulk-edit-modal select,\n.bulk-edit-modal input {\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-family: \"Fivo Sans Light\", sans-serif;\n}\n\n.bulk-edit-buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.bulk-edit-buttons button {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  background-color: #b92328;\n  color: white;\n  cursor: pointer;\n  font-family: \"Fivo Sans Medium\", sans-serif;\n}\n\n.bulk-edit-buttons button:hover {\n  background-color: #b51f25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
