import React, { useState } from "react";
import "./Card.css";

const Card = ({
  image,
  name,
  topSpecs,
  specs,
  isSelected,
  onClick,
  tag,
  noHover,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Function to parse and style specs for hover
  const parseAndStyleSpecs = (specs) => {
    const regex = /(\w+): ([^,]+)/g;
    let matches = [];
    let match;
    while ((match = regex.exec(specs)) !== null) {
      matches.push(
        `<span class="spec-key">${match[1]}:</span> <span class="spec-value">${match[2]}</span>`
      );
    }

    // Join the matches with a separator and break the lines
    return matches.join(", ").replace(/, $/, "").replace(/, /g, ",<br/>");
  };
  const shouldShowHover = !noHover;
  return (
    <div
      className={`card ${isSelected ? "selected" : ""} ${
        noHover ? "no-hover" : ""
      }`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="image-container">
        {tag && <div className="tag">{tag}</div>}
        <img src={image} alt={name} />
      </div>
      <h3>{name}</h3>
      <p className="top-specs">{topSpecs}</p>
      {shouldShowHover && isHovered && (
        <div className="card-expanded">
          <p
            dangerouslySetInnerHTML={{ __html: parseAndStyleSpecs(specs) }}
          ></p>
        </div>
      )}
      {isSelected && <div className="checkmark">✓</div>}
    </div>
  );
};

export default Card;
