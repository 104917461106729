// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-image: linear-gradient(to bottom right, #003c75, #00213c);
  height: 3.75em;
  width: 100vw;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: "Fivo Sans Light", sans-serif;

  font-size: 0.8846375em;
}
.footer-content p {
  margin: 1.5em 0 0.625em 18.75em;
}
.footer-links {
  display: flex;
  margin: 1.5em 18.75em 0.625em 1.5em;
  gap: 2em;

  font-size: 0.8846375em;
}
.footer-links a {
  color: #fff;
  font-family: "Fivo Sans Thin", sans-serif;
  text-decoration: none;
}
.footer-links a:hover {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 769px) {
  .footer {
    height: auto;
    padding: 1em 0;
  }
  .footer-content {
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    font-size: 0.8333em;
  }
  .footer-content p {
    margin: 0 0 1em 0;
  }
  .footer-links {
    flex-direction: row;
    justify-content: left;
    align-items: left;
    margin: 0;
    gap: 0.5em;
    font-size: 0.8333em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,oEAAoE;EACpE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,0CAA0C;;EAE1C,sBAAsB;AACxB;AACA;EACE,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,mCAAmC;EACnC,QAAQ;;EAER,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,yCAAyC;EACzC,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE;IACE,YAAY;IACZ,cAAc;EAChB;EACA;IACE,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,mBAAmB;EACrB;AACF","sourcesContent":[".footer {\n  background-image: linear-gradient(to bottom right, #003c75, #00213c);\n  height: 3.75em;\n  width: 100vw;\n}\n\n.footer-content {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #fff;\n  font-family: \"Fivo Sans Light\", sans-serif;\n\n  font-size: 0.8846375em;\n}\n.footer-content p {\n  margin: 1.5em 0 0.625em 18.75em;\n}\n.footer-links {\n  display: flex;\n  margin: 1.5em 18.75em 0.625em 1.5em;\n  gap: 2em;\n\n  font-size: 0.8846375em;\n}\n.footer-links a {\n  color: #fff;\n  font-family: \"Fivo Sans Thin\", sans-serif;\n  text-decoration: none;\n}\n.footer-links a:hover {\n  color: #fff;\n  text-decoration: underline;\n}\n\n@media (max-width: 769px) {\n  .footer {\n    height: auto;\n    padding: 1em 0;\n  }\n  .footer-content {\n    flex-direction: column;\n    align-items: left;\n    justify-content: left;\n    text-align: left;\n    font-size: 0.8333em;\n  }\n  .footer-content p {\n    margin: 0 0 1em 0;\n  }\n  .footer-links {\n    flex-direction: row;\n    justify-content: left;\n    align-items: left;\n    margin: 0;\n    gap: 0.5em;\n    font-size: 0.8333em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
