import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Card.css";
import { backendURL } from "../../config";
// const backendUrl = process.env.REACT_APP_BACKEND_URL;
// const backendUrl = "http://localhost:8080";

const AccessoryStep = ({
  onNext,
  onPrev,
  updateFormData,
  selectedComponents,
}) => {
  const [localSelectedOptics, setLocalSelectedOptics] = useState(
    selectedComponents.optics || []
  );
  const [localSelectedHeatSinks, setLocalSelectedHeatSinks] = useState(
    selectedComponents.heatSinks || []
  );
  const [localSelectedConnectors, setLocalSelectedConnectors] = useState(
    selectedComponents.connectors || []
  );
  const [optics, setOptics] = useState([]);
  const [heatSinks, setHeatSinks] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch accessory options based on selectedComponents specs
    const fetchAccessories = async () => {
      try {
        const [opticsResponse, heatSinksResponse, connectorsResponse] =
          await Promise.all([
            fetch(`${backendURL}/lens`),
            fetch(`${backendURL}/heatSinks`),
            fetch(`${backendURL}/connectors`),
          ]);
        const opticsData = await opticsResponse.json();
        const heatSinksData = await heatSinksResponse.json();
        const connectorsData = await connectorsResponse.json();
        // log selected components to debug
        console.log("Selected Components: ", selectedComponents);

        // Log lenses options from selected components
        const lensesOptions =
          selectedComponents.module?.[0]?.lensesOptions || [];
        console.log("Lenses Options: ", lensesOptions);

        //filter optics based on lenses options
        const filteredOptics = opticsData.filter((optic) =>
          lensesOptions.includes(optic.part_name)
        );
        console.log("Filtered Optics: ", filteredOptics);

        setOptics(filteredOptics.length > 0 ? filteredOptics : []);

        setConnectors(connectorsData);

        //conditional logic to filter heat sinks based on PCB part-name
        const pcbPartName = selectedComponents.module?.[0]?.part_name || "";
        let filteredHeatSinks = [];
        // Check for SQ-01 heatsinks
        if (
          pcbPartName.startsWith("1966-A") ||
          pcbPartName.startsWith("2065-A")
        ) {
          filteredHeatSinks = heatSinksData.filter((heatSink) =>
            heatSink.part_name.includes("SQ-01-2X2MX-B")
          );
        }
        // Check for LX-01 heatsinks
        else if (pcbPartName.startsWith("1950")) {
          filteredHeatSinks = heatSinksData.filter((heatSink) =>
            heatSink.part_name.includes("LX-01-2X6-B")
          );
        }

        if (filteredHeatSinks.length > 0) {
          setHeatSinks(filteredHeatSinks);
        } else {
          console.log(
            "No heatsinks option available. Contact sales for more info."
          );
          setHeatSinks([]); // or set an appropriate state to indicate no options available
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessories();
  }, [selectedComponents]);

  const handlePrev = () => {
    onPrev();
  };

  const handleNext = () => {
    updateFormData("accessories", {
      lens: localSelectedOptics,
      heatSink: localSelectedHeatSinks,
      connector: localSelectedConnectors,
    });
    onNext();
  };

  const handleOpticClick = (optic) => {
    setLocalSelectedOptics((prevSelectedOptics) => {
      if (prevSelectedOptics.includes(optic)) {
        return prevSelectedOptics.filter((item) => item !== optic);
      } else {
        return [...prevSelectedOptics, optic];
      }
    });
  };

  const handleHeatSinkClick = (heatSink) => {
    setLocalSelectedHeatSinks((prevSelectedHeatSinks) => {
      if (prevSelectedHeatSinks.includes(heatSink)) {
        return prevSelectedHeatSinks.filter((item) => item !== heatSink);
      } else {
        return [...prevSelectedHeatSinks, heatSink];
      }
    });
  };

  const handleConnectorClick = (connector) => {
    setLocalSelectedConnectors((prevSelectedConnectors) => {
      if (prevSelectedConnectors.includes(connector)) {
        return prevSelectedConnectors.filter((item) => item !== connector);
      } else {
        return [...prevSelectedConnectors, connector];
      }
    });
  };
  if (loading) {
    return <div className="loading-spinner"></div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="step-container">
      <h2 className="accessories-title">Select Accessories</h2>
      <div className="card-section">
        <h3 className="card-section-title">Lenses</h3>
        {optics.length > 0 ? (
          <div className="card-container">
            {optics.map((optic) => {
              const specs = [
                `${optic.specs.Beam} | ${optic.specs.Seal} | ${optic.specs.Dimension}`,
              ];

              const allSpecs = [
                `Beam Angle: ${optic.specs.Beam}`,
                `Seal: ${optic.specs.Seal}`,
                `Dimension: ${optic.specs.Dimension}`,
                `Material: ${optic.specs.Material}`,
              ];
              return (
                <div key={optic.id} onClick={() => handleOpticClick(optic)}>
                  <Card
                    image={optic.image || "https://via.placeholder.com/150"}
                    name={optic.part_name}
                    specs={allSpecs}
                    topSpecs={specs}
                    isSelected={localSelectedOptics.includes(optic)}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="accessories-text">
            No lenses option available. Contact sales for more info.
          </div>
        )}
      </div>
      <div className="card-section">
        <h3 className="card-section-title">Heatsinks</h3>
        {heatSinks.length > 0 ? (
          <div className="card-container">
            {heatSinks.map((heatSink) => {
              const specs = [
                `${heatSink.specs.Weight} | ${heatSink.specs.Dimension} | ${heatSink.specs.Material}`,
              ];

              const allSpecs = [
                `Weight: ${heatSink.specs.Weight}`,
                `Dimension: ${heatSink.specs.Dimension}`,
                `Material: ${heatSink.specs.Material}`,
              ];
              return (
                <div
                  key={heatSink.id}
                  onClick={() => handleHeatSinkClick(heatSink)}
                >
                  <Card
                    image={heatSink.image || "https://via.placeholder.com/150"}
                    name={heatSink.part_name}
                    specs={allSpecs}
                    topSpecs={specs}
                    isSelected={localSelectedHeatSinks.includes(heatSink)}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="accessories-text">
            No heatsinks option available. Contact sales for more info.
          </div>
        )}
      </div>
      <div className="card-section">
        <h3 className="card-section-title">Connectors</h3>
        <div className="card-container">
          {connectors.map((connector) => {
            const specs = [
              `${connector.specs.Type} | ${connector.specs.Current} | ${connector.specs.Material}`,
            ];
            const allSpecs = [
              `Type: ${connector.specs.Type}`,
              `Current: ${connector.specs.Current}`,
              `Material: ${connector.specs.Material}`,
            ];
            return (
              <div
                key={connector.id}
                onClick={() => handleConnectorClick(connector)}
              >
                <Card
                  image={connector.image || "https://via.placeholder.com/150"}
                  name={connector.part_name}
                  specs={allSpecs}
                  topSpecs={specs}
                  isSelected={localSelectedConnectors.includes(connector)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="navigation-buttons">
        <button className="nav-button-prev" onClick={handlePrev}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          className="nav-button-next"
          onClick={handleNext}
          // disabled={
          //   localSelectedOptics.length === 0 &&
          //   localSelectedHeatSinks.length === 0 &&
          //   localSelectedConnectors.length === 0
          // }
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default AccessoryStep;
