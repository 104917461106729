import React from "react";
import "./Home.css";
import coolbeam from "../assets/images/coolbeam.webp";
const Home = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Build Your Perfect Lighting System</h1>
        <p>
          Easily design and customize your lighting solutions. Choose from a
          wide range of components to create your ideal setup.
        </p>
        <div className="cta-buttons">
          <a href="/create" className="primary-btn">
            Get Started
          </a>
        </div>
      </div>
      <div className="hero-image">
        <img src={coolbeam} alt="hero" className="superimposed-image" />
      </div>
    </div>
  );
};

export default Home;
