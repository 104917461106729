import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Card.css";
import { backendURL } from "../../config";
import Modal from "../Auth/Modal";
import emailjs from "emailjs-com";
import { useAuth } from "../../AuthContext";

const ModuleClass = ({ onNext, updateFormData, selectedClass }) => {
  const [moduleClasses, setModuleClasses] = useState([]);
  const [filteredModuleClasses, setFilteredModuleClasses] = useState([]);
  const [localSelectedClass, setLocalSelectedClass] = useState(selectedClass);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [proposalRequestSent, setProposalRequestSent] = useState(false);
  const { user } = useAuth();

  const [filters, setFilters] = useState({
    shape: "",
    size: "",
    material: "",
    supplier: "",
  });

  const [filterOptions, setFilterOptions] = useState({
    shapes: [],
    sizes: [],
    materials: [],
    suppliers: [],
  });
  const [proposalFormData, setProposalFormData] = useState({
    shape: "",
    size: "",
    cct: "",
    cri: "",
    wattage: "",
    lumens: "",
    lpw: "",
    otherRequirements: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/modules`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Ensure all necessary fields are available, provide default values if not
        const cleanData = data.map((pcb) => ({
          ...pcb,
          specs: {
            ...pcb.specs,
            Shape: pcb.specs.Shape || "Unknown",
            Size: pcb.specs.Size + " in" || "Unknown",
            Material: pcb.specs.Material || "Unknown",
            Supplier: pcb.specs.Supplier || "Unknown",
          },
        }));

        const shapes = [...new Set(cleanData.map((pcb) => pcb.specs.Shape))];
        const sizes = [...new Set(cleanData.map((pcb) => pcb.specs.Size))];
        const materials = [
          ...new Set(cleanData.map((pcb) => pcb.specs.Material)),
        ];
        const suppliers = [
          ...new Set(cleanData.map((pcb) => pcb.specs.Supplier)),
        ];

        setFilterOptions({ shapes, sizes, materials, suppliers });

        const classes = [
          ...new Set(cleanData.map((pcb) => pcb.part_name.split("-")[0])),
        ].map((className) => {
          const classPCBs = cleanData.filter((pcb) =>
            pcb.part_name.startsWith(className)
          );
          const cctValues = classPCBs.map((pcb) =>
            parseInt(pcb.specs.CCT.replace("K", ""), 10)
          );
          const lumensValues = classPCBs.map((pcb) => pcb.specs.Lumens);
          const cctMin = Math.min(...cctValues);
          const cctMax = Math.max(...cctValues);
          const lumensMin = Math.min(...lumensValues);
          const lumensMax = Math.max(...lumensValues);
          const image =
            classPCBs[0]?.image || "https://via.placeholder.com/150";
          return {
            className,
            cctMin,
            cctMax,
            lumensMin,
            lumensMax,
            cri: [...new Set(classPCBs.map((pcb) => pcb.specs.CRI))],
            size: [...new Set(classPCBs.map((pcb) => pcb.specs.Size))].join(
              ", "
            ),
            shape: [...new Set(classPCBs.map((pcb) => pcb.specs.Shape))].join(
              ", "
            ),
            material: [
              ...new Set(classPCBs.map((pcb) => pcb.specs.Material)),
            ].join(", "),
            supplier: [
              ...new Set(classPCBs.map((pcb) => pcb.specs.Supplier)),
            ].join(", "),
            image,
          };
        });
        setModuleClasses(classes);
        setFilteredModuleClasses(classes);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredClasses = moduleClasses.filter((cls) => {
      return (
        (!filters.shape || cls.shape.includes(filters.shape)) &&
        (!filters.size || cls.size.includes(filters.size)) &&
        (!filters.material || cls.material.includes(filters.material)) &&
        (!filters.supplier || cls.supplier.includes(filters.supplier))
      );
    });
    setFilteredModuleClasses(filteredClasses);
    updateFilterOptions(filteredClasses);
  }, [filters, moduleClasses]);

  const updateFilterOptions = (data) => {
    const shapes = [...new Set(data.flatMap((pcb) => pcb.shape.split(", ")))];
    const sizes = [...new Set(data.flatMap((pcb) => pcb.size.split(", ")))];
    const materials = [
      ...new Set(data.flatMap((pcb) => pcb.material.split(", "))),
    ];
    const suppliers = [
      ...new Set(data.flatMap((pcb) => pcb.supplier.split(", "))),
    ];

    setFilterOptions({ shapes, sizes, materials, suppliers });
  };

  const handleProposalFormChange = (e) => {
    const { name, value } = e.target;
    setProposalFormData({
      ...proposalFormData,
      [name]: value,
    });
  };
  const handleCardClick = (pcbClass) => {
    updateFormData("moduleClass", pcbClass);
    setLocalSelectedClass(pcbClass);
    onNext();
  };

  const handleProposalRequestClick = async (e) => {
    e.preventDefault();

    if (proposalRequestSent) {
      alert("You have already sent a proposal request for this module class.");
      return;
    }

    const templateParams = {
      to_email: "sales2@adurasolutions.com",
      user_email: user.company_email,
      user_name: user.full_name,
      shape: proposalFormData.shape,
      cct: proposalFormData.cct,
      cri: proposalFormData.cri,
      wattage: proposalFormData.wattage,
      lumens: proposalFormData.lumens,
      lpw: proposalFormData.lpw,
      otherRequirements: proposalFormData.otherRequirements || "",
    };

    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_PROPOSAL_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );
      console.log("Proposal request sent successfully: ", result.text);

      alert("Proposal request sent successfully");
      setProposalRequestSent(true);
      localStorage.setItem("proposalRequestSent", true);
      setModalOpen(false);
    } catch (error) {
      console.error("Error sending proposal request or auto-reply: ", error);
      alert("Error sending proposal request. Please try again later.");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="step-container">
      <h2 className="moduleClass-title">Select PCB</h2>
      <div className="filters">
        <label>
          Shape:
          <select
            name="shape"
            value={filters.shape}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.shapes.map((shape) => (
              <option key={shape} value={shape}>
                {shape}
              </option>
            ))}
          </select>
        </label>
        <label>
          Size:
          <select
            name="size"
            value={filters.size}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.sizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </label>
        <label>
          Material:
          <select
            name="material"
            value={filters.material}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.materials.map((material) => (
              <option key={material} value={material}>
                {material}
              </option>
            ))}
          </select>
        </label>
        <label>
          Supplier:
          <select
            name="supplier"
            value={filters.supplier}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.suppliers.map((supplier) => (
              <option key={supplier} value={supplier}>
                {supplier}
              </option>
            ))}
          </select>
        </label>
      </div>{" "}
      <button className="open-modal-button" onClick={() => setModalOpen(true)}>
        Can't find what you're looking for?
      </button>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <h3>
            If you’re unable to find what you need, please share the details
            below to receive a tailored proposal from ADURA.
          </h3>
          <form onSubmit={handleProposalRequestClick}>
            <div class="form-row">
              <div class="form-group">
                <label>
                  Module Shape:<span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="shape"
                  value={proposalFormData.shape}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
              <div class="form-group">
                <label>
                  Module Size:<span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="size"
                  value={proposalFormData.size}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>
                  CCT (K):<span class="required">*</span>
                </label>
                <input
                  type="number"
                  name="cct"
                  value={proposalFormData.cct}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
              <div class="form-group">
                <label>
                  CRI:<span class="required">*</span>
                </label>
                <input
                  type="number"
                  name="cri"
                  value={proposalFormData.cri}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>
                  Wattage (W):<span class="required">*</span>
                </label>
                <input
                  type="number"
                  name="wattage"
                  value={proposalFormData.wattage}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
              <div class="form-group">
                <label>
                  Lumens Requirement (Lm):<span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="lumens"
                  value={proposalFormData.lumens}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>
                  Minimum Lumens per Watt (LPW):<span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="lpw"
                  value={proposalFormData.lpw}
                  onChange={handleProposalFormChange}
                  required
                />
              </div>
              <div class="form-group">
                <label>Annual Usage/ Quantity:</label>
                <input
                  type="text"
                  name="Annual Usage / Quantity"
                  value={proposalFormData.annualUsage}
                  onChange={handleProposalFormChange}
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Unit Price:</label>
                <input
                  type="text"
                  name="unitPrice"
                  value={proposalFormData.unitPrice}
                  onChange={handleProposalFormChange}
                />
              </div>
            </div>
            <div class="form-row"></div>
            <div class="form-group full-width">
              <label>Any other requirements:</label>
              <textarea
                name="otherRequirements"
                value={proposalFormData.otherRequirements}
                onChange={handleProposalFormChange}
              ></textarea>
            </div>
            <button type="submit" class="submit-button">
              Submit
            </button>
          </form>
        </Modal>
      )}
      <div className="card-container">
        {filteredModuleClasses.map((cls) => {
          const specs = [
            `${cls.cctMin}K - ${cls.cctMax}K`,
            `${cls.cri.join(", ")}`,
            `${cls.lumensMin} lm - ${cls.lumensMax} lm`,
            `${cls.size}`,
          ].join(" | ");
          const formattedName = `${cls.className}-A-XXX-3535`;
          return (
            <Card
              key={cls.className}
              image={cls.image}
              name={formattedName}
              topSpecs={specs}
              onClick={() => handleCardClick(cls.className)}
              noHover
              isSelected={cls.className === localSelectedClass}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModuleClass;
