import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { backendURL } from "../../config";
import "./CategoryTable.css";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import EditModal from "../YourCreations/EditModal";
import { FaArrowLeft, FaPlus, FaFilter } from "react-icons/fa";
import FilterModal from "./FilterModal";
import BulkEditModal from "./BulkEditModal";

const CategoryTable = () => {
  const { category } = useParams();
  const { user } = useAuth();
  const uid = user ? user.uid : null;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editItem, setEditItem] = useState(null);
  const [newItem, setNewItem] = useState({
    part_name: "",
    image: "",
    link: "",
    specs: {},
    lensOptions: [],
  });
  const [lensOptions, setLensOptions] = useState([]);
  const [filterModal, setFilterModal] = useState({ isOpen: false, column: "" });
  const [filters, setFilters] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]); // New state for selected rows
  const [showBulkEdit, setShowBulkEdit] = useState(false); // New state for showing the bulk edit modal
  const itemsPerPage = 50;
  const navigate = useNavigate();

  const hiddenColumns = {
    modules: [],
    drivers: ["specs", "FK_Market", "FK_Application", "Supplier", "Series"],
    heatsinks: ["FK_MARKET", "FK_MODULES", "FK_MODULES.1", "FK_APPLICATION"],
    lens: ["FK_MARKET", "FK_MODULES", "FK_MODULES.1", " FK_APPLICATION"],
    connectors: [
      "specs",
      "Application",
      "compatible_with",
      "CONNECTOR_PNUMBER",
      "CONNECTOR_CATEGORY",
    ],
  };

  const columnOrders = {
    modules: [
      "part_name",
      "image",
      "CCT",
      "CRI",
      "Size",
      "Power",
      "Current",
      "Voltage",
      "Lumens",
      "Efficiency",
      "Material",
      "Supplier",
      "Shape",
      "Lenses Options",
      "Channel",
    ],
    drivers: [
      "part_name",
      "image",
      "APL",
      "Type",
      "Input Voltage",
      "Output Voltage",
      "Output Current",
      "Power",
      "Dimming",
      "class2",
      "classp",
      "Dimension",
      "IP Rating",
      "Programmable",
    ],
    heatsinks: [
      "part_name",
      "image",
      "APL",
      "Weight",
      "Material",
      "Dimension",
      "Thermal Resistance",
      "HEATSINK_CAD",
    ],
    lens: [
      "part_name",
      "image",
      "APL",
      "Beam",
      "Seal",
      "Material",
      "Dimension",
      "LENS_CAD",
    ],
    connectors: [
      "part_name",
      "image",
      "APL",
      "Type",
      "Current",
      "Voltage",
      "Material",
      "Dimension",
      "IP Rating",
    ],
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(`${backendURL}/admin/products`, {
        params: { category, uid },
      });
      const itemsData = data[category] || [];
      setItems(itemsData);
      setFilteredItems(itemsData);

      if (itemsData.length > 0) {
        const specsKeys = Object.keys(itemsData[0].specs || {});
        const initialSpecs = {};
        specsKeys.forEach((key) => {
          initialSpecs[key] = "";
        });
        setNewItem((prevState) => ({
          ...prevState,
          specs: initialSpecs,
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [category, uid]);

  useEffect(() => {
    if (!category) return;
    fetchData();
  }, [category, uid, fetchData]);

  useEffect(() => {
    const fetchLensOptions = async () => {
      try {
        const lensCategory = "lens";
        const { data } = await axios.get(`${backendURL}/admin/products`, {
          params: { category: lensCategory, uid },
        });
        const lensPartNumber = data.lens
          ? data.lens.map((lens) => lens.part_name)
          : [];
        console.log("Fetched Lens Options:", lensPartNumber); // Log the fetched lens options
        setLensOptions(lensPartNumber);
      } catch (error) {
        console.error("Error fetching lens options:", error);
      }
    };

    fetchLensOptions();
  }, [uid]);

  const handleSave = async (item) => {
    try {
      // Extract the necessary fields
      const payload = {
        id: item.id, // Include ID only if it's an existing item
        part_name: item.part_name,
        image: item.image,
        link: item.link,
        specs: {
          ...item.specs,
        },
      };

      if (item.id) {
        // Update existing item
        await axios.post(`${backendURL}/admin/products/update?uid=${uid}`, {
          category,
          item: payload, // Send the mapped payload
          uid,
        });

        const updatedItems = items.map((i) => (i.id === item.id ? payload : i));
        setItems(updatedItems);
      } else {
        // Add new item
        const { data } = await axios.post(
          `${backendURL}/admin/products/add?uid=${uid}`,
          {
            category,
            item: payload, // Send the mapped payload
            uid,
          }
        );

        setItems([...items, data]);
      }

      applyFilters(filters);

      alert("Item saved successfully");
      fetchData();
    } catch (error) {
      console.error("Error saving item:", error);
      alert(
        "Failed to save the item. Please check the console for more details."
      );
    }
  };

  const handleAddNewItem = () => {
    setEditItem({
      part_name: "",
      image: "",
      link: "",
      specs: newItem.specs,
      lensOptions: [],
    });
  };

  const handleFilterClick = (column) => {
    setFilterModal({ isOpen: true, column });
  };

  const handleApplyFilter = (column, values) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: values,
    }));
    // Apply filters immediately
    const newFilters = { ...filters, [column]: values };
    applyFilters(newFilters);
    setFilterModal({ isOpen: false, column: "" });
  };

  const handleCloseFilter = () => {
    setFilterModal({ isOpen: false, column: "" });
  };

  const applyFilters = (filters) => {
    let filtered = [...items];
    Object.keys(filters).forEach((column) => {
      if (filters[column] && filters[column].length > 0) {
        filtered = filtered.filter((item) => {
          const value = item[column] || item.specs[column];
          return filters[column].includes(value);
        });
      }
    });
    setFilteredItems(filtered);
    setCurrentPage(1);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const columnOrder = columnOrders[category] || [];
  const hiddenSpecsKeys = hiddenColumns[category] || [];

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleBulkEditApply = (column, newValue) => {
    const updatedItems = items.map((item) =>
      selectedRows.includes(item.id)
        ? {
            ...item,
            [column]: newValue,
            specs: {
              ...item.specs,
              [column]: newValue,
            },
          }
        : item
    );
    setItems(updatedItems);
    setFilteredItems(updatedItems);

    console.log("Sending updates:", {
      category,
      updates: selectedRows.map((id) => ({
        id,
        column,
        newValue,
      })),
      uid,
    });
    // Optionally, send bulk updates to the backend here
    axios
      .post(`${backendURL}/admin/products/bulk-update?uid=${uid}`, {
        category, // Ensure this matches the category you want to update
        updates: selectedRows.map((id) => ({
          id, // ID of the item to update
          column, // The column you want to update
          newValue, // The new value you want to set
        })),
        uid, // If tracking the user is necessary
      })
      .then((response) => {
        console.log("Bulk update successful:", response.data);
        alert(`Bulk update successful for ${selectedRows.length} rows`);
      })
      .catch((error) => {
        console.error("Error performing bulk update:", error);
      });

    setSelectedRows([]); // Clear selected rows after bulk edit
  };

  if (loading) {
    return (
      <div className="category-loading-spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  const formatHeader = (key) => {
    return key
      .split("_")
      .join(" ")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  };

  return (
    <div className="category-table-container">
      <div className="category-table-wrapper">
        <div className="category-table-header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <FaArrowLeft />
          </button>
          <h1 className="category-table-heading">
            {category && category.charAt(0).toUpperCase() + category.slice(1)}
          </h1>
          <div></div>
        </div>

        <div className="table-wrapper">
          <table className="category-table">
            <thead className="category-table-heading">
              <tr className="category-table-row">
                <th className="checkbox-column">
                  <input
                    type="checkbox"
                    checked={
                      selectedRows.length === currentItems.length &&
                      currentItems.length > 0
                    }
                    onChange={(e) =>
                      setSelectedRows(
                        e.target.checked
                          ? currentItems.map((item) => item.id)
                          : []
                      )
                    }
                    className="checkbox"
                  />
                </th>
                {columnOrder.map(
                  (key) =>
                    !hiddenSpecsKeys.includes(key) && (
                      <th className="category-table-row-heading" key={key}>
                        <div className="header-content">
                          <span className="header-label">
                            {formatHeader(key)}
                          </span>
                          <FaFilter
                            className={`filter-icon ${
                              filters[key] && filters[key].length > 0
                                ? "filter-icon-active"
                                : ""
                            }`}
                            onClick={() => handleFilterClick(key)}
                          />
                        </div>
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr
                  className={`category-table-row ${
                    selectedRows.includes(item.id) ? "selected" : ""
                  }`}
                  key={item.id}
                  onClick={(e) => {
                    //prevent opending modal if clicking a link, checkbox, or highlighting text
                    if (
                      e.target.tagName === "A" ||
                      e.target.tagName === "INPUT" ||
                      window.getSelection().toString()
                    ) {
                      return;
                    }
                    setEditItem(item);
                  }}
                >
                  <td className="checkbox-column">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)}
                      onChange={() => handleRowSelect(item.id)}
                      className="checkbox"
                    />
                  </td>
                  {columnOrder.map(
                    (key) =>
                      !hiddenSpecsKeys.includes(key) && (
                        <td className="category-table-row-detail" key={key}>
                          {key === "part_name" ? (
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item[key]}
                            </a>
                          ) : key === "image" ? (
                            <img src={item[key]} alt={item.part_name} />
                          ) : key === "Lenses Options" ? (
                            item.specs["Lenses Options"] &&
                            item.specs["Lenses Options"] !== "None" ? (
                              item.specs["Lenses Options"]
                                .split(", ")
                                .join(", ")
                            ) : (
                              "None"
                            )
                          ) : (
                            item[key] || item.specs[key]
                          )}
                        </td>
                      )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination controls */}
        <div className="category-pagination">
          {Array.from(
            { length: Math.ceil(filteredItems.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`pagination-button ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            )
          )}
        </div>

        <div className="edit-button-container">
          {" "}
          <div
            className="bulk-edit-button"
            onClick={() => setShowBulkEdit(true)}
          >
            <FaPlus />
            &ensp;Bulk Edit
          </div>
          <div className="add-new-item-button" onClick={handleAddNewItem}>
            <FaPlus />
            &ensp;Add New
          </div>
        </div>
      </div>
      {editItem && (
        <EditModal
          item={editItem}
          onSave={handleSave} // Save both new and existing items
          onClose={() => setEditItem(null)}
          lensOptions={lensOptions} // Pass the lens options to the modal
          category={category} // Pass the category to the modal
        />
      )}
      {filterModal.isOpen && (
        <FilterModal
          column={filterModal.column}
          items={filteredItems} // Pass filtered items to ensure filter options reflect current table state
          onClose={handleCloseFilter}
          onApply={handleApplyFilter}
          selectedValues={filters[filterModal.column] || []}
        />
      )}
      {showBulkEdit && (
        <BulkEditModal
          columns={columnOrder.filter((col) => !hiddenSpecsKeys.includes(col))}
          onApply={handleBulkEditApply}
          onClose={() => setShowBulkEdit(false)}
        />
      )}
    </div>
  );
};

export default CategoryTable;
