import React, { useEffect, useRef, useState } from "react";
import "./CustomDropdown.css";

const CustomDropdown = ({ options, onChange, value, labelKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={handleToggle}>
        {value ? value[labelKey] : "Select Part Number"}
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`} />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((group) => (
            <React.Fragment key={group.label}>
              <div className="dropdown-group-label">{group.label}</div>
              {group.options.map((option) => (
                <div
                  key={option[labelKey]}
                  className="dropdown-option"
                  onClick={() => handleOptionClick(option)}
                >
                  <span className="option-label">{option[labelKey]}</span>
                  <span className="option-range">
                    ({option["Min Range (A)"]} - {option["Max Range (A)"]}A)
                  </span>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
