import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import BuildCard from "../../components/YourCreations/BuildCard";
// @ts-ignore
import BuildSidebar from "../../components/YourCreations/BuildSidebar";
import "../../components/YourCreations/BuildCard.css";
import "./YourCreations.css";
import { backendURL } from "../../config";

// const backendURL = process.env.REACT_APP_BACKEND_URL;
// const backendURL = "http://localhost:8080";
const YourCreations = () => {
  const { user } = useAuth();
  //   console.log("User: ", user);
  const [builds, setBuilds] = useState([]);
  const [selectedBuild, setSelectedBuild] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.uid) {
      const fetchBuilds = async () => {
        try {
          const response = await fetch(
            `${backendURL}/fetchBuilds?uid=${user.uid}`
          );
          const data = await response.json();
          setBuilds(data);
        } catch (error) {
          console.error("Error fetching builds: ", error);
        }
      };
      fetchBuilds();
    }
  }, [user]);

  const handleAddBuild = async () => {
    // console.log("user: ", user.uid);
    navigate("/create");
  };

  const handleEditBuildName = async (buildId, newBuildName) => {
    try {
      const response = await fetch(`${backendURL}/updateBuildName`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ buildId, buildName: newBuildName }),
      });

      if (response.ok) {
        setBuilds((prevBuilds) =>
          prevBuilds.map((build) =>
            build.buildId === buildId
              ? { ...build, buildName: newBuildName }
              : build
          )
        );
      } else {
        console.error("Error updating build name");
      }
    } catch (error) {
      console.error("Error updating build name: ", error);
    }
  };

  const handleDeleteBuild = async (buildId) => {
    try {
      const response = await fetch(`${backendURL}/deleteBuild`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ buildId }),
      });

      if (response.ok) {
        setBuilds((prevBuilds) =>
          prevBuilds.filter((build) => build.buildId !== buildId)
        );
      } else {
        console.error("Error deleting build");
      }
    } catch (error) {
      console.error("Error deleting build: ", error);
    }
  };
  const handleBuildClick = (build) => {
    console.log("Selected build: ", build);
    setSelectedBuild(build);
  };

  const handleSidebarClose = () => {
    setSelectedBuild(null);
  };
  const handleEditTitle = () => {
    const newTitle = prompt("Enter new build title:", selectedBuild.buildName);
    if (newTitle) {
      handleEditBuildName(selectedBuild.buildId, newTitle);
    }
  };

  const handleEditBuild = () => {
    navigate(`/create?buildId=${selectedBuild.buildId}`, {
      state: {
        buildId: selectedBuild.buildId,
        buildName: selectedBuild.buildName,
      },
    });
  };
  return (
    <div className="your-creations-container">
      <h2 className="your-creations-title">Your Creations</h2>
      <div className="build-cards-container">
        {builds.map((build) => (
          <BuildCard
            key={build.buildId}
            build={build}
            onClick={() => handleBuildClick(build)}
            onEditName={handleEditBuildName}
            onDelete={handleDeleteBuild}
          >
            <h3>
              {build.buildName}
              <button
                className="edit-build-name-button"
                onClick={() => handleEditBuildName(build.buildId)}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
            </h3>
            <div className="build-summary">
              {/* Display components summary here */}
              {Object.keys(build.buildData).map((key) => (
                <div key={key}>
                  <strong>{key}:</strong>{" "}
                  {Array.isArray(build.buildData[key])
                    ? build.buildData[key]
                        .slice(0, 3)
                        .map((comp) => comp.name)
                        .join(", ")
                    : ""}
                  {Array.isArray(build.buildData[key]) &&
                    build.buildData[key].length > 3 &&
                    " ..."}
                </div>
              ))}
            </div>
          </BuildCard>
        ))}
        <div className="add-build-card" onClick={handleAddBuild}>
          <div className="add-build-icon">
            <i className="fas fa-plus"></i>
          </div>
        </div>
      </div>
      {selectedBuild && (
        <BuildSidebar
          build={selectedBuild}
          onClose={handleSidebarClose}
          onEditTitle={handleEditTitle}
          onEditBuild={handleEditBuild}
        />
      )}
    </div>
  );
};
export default YourCreations;
