// CustomContextMenu.js
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FaTimes } from "react-icons/fa";
import "../YourCreations/CustomContextMenu.css";

const CustomContextMenu = ({ visible, position, onClose, items }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible, onClose]);

  if (!visible) return null;

  return (
    <div
      ref={menuRef}
      className="custom-context-menu"
      style={{ top: position.y, left: position.x }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className="custom-context-menu-item"
          onClick={item.onClick}
          style={{ color: item.color }}
        >
          {item.icon} {item.label}
        </div>
      ))}
      <div
        className="custom-context-menu-item"
        onClick={onClose}
        style={{ color: "#333" }}
      >
        <FaTimes /> Close
      </div>
    </div>
  );
};

CustomContextMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.element.isRequired,
    })
  ).isRequired,
};

export default CustomContextMenu;
