import { auth } from "./firebase"; // Assuming you have configured Firebase in another file
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { backendURL } from "./config"; // Backend URL configuration

// Sign up
const signUp = async (fullName, companyName, companyEmail, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      companyEmail,
      password
    );
    const user = userCredential.user;

    // Send email verification
    await sendEmailVerification(user);

    // Save user data to your backend database
    await saveUserToDatabase(user.uid, fullName, companyName, companyEmail);
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

// Login
const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Check if the email is verified
    if (!userCredential.user.emailVerified) {
      throw new Error("Please verify your email before logging in.");
    }

    return userCredential.user;
  } catch (error) {
    console.error("Error logging in: ", error);
    throw error;
  }
};

//Reset Password
const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent!");
  } catch (error) {
    console.error("Error sending password reset email: ", error);
    throw error;
  }
};
// Listen for auth state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log("User is logged in: ", user);
  } else {
    console.log("User is logged out");
  }
});

// Save user to database
const saveUserToDatabase = async (uid, fullName, companyName, companyEmail) => {
  try {
    const response = await fetch(`${backendURL}/saveUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, fullName, companyName, companyEmail }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("User saved to database");
  } catch (error) {
    console.error("Error saving user to database: ", error);
    throw error;
  }
};

export { signUp, login, resetPassword };
