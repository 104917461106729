// src/components/ProgressBar.js
import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ step, totalSteps, onStepClick }) => {
  return (
    <div className="progress-bar">
      {Array.from({ length: totalSteps }, (_, i) => (
        <div
          key={i}
          className={`step ${i < step ? "completed" : ""}`}
          onClick={() => onStepClick(i)}
        >
          {i + 1}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
