import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import "./App.css";
import { backendURL } from "./config";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // const backendUrl = "http://localhost:8080";

  const fetchUserProfile = async (uid) => {
    try {
      const response = await fetch(`${backendURL}/getUserProfile?uid=${uid}`);
      const data = await response.json();
      setUser({ uid, ...data });
      console.log("User profile fetched: ", { uid, ...data });
    } catch (error) {
      console.error("Error fetching user profile: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User is logged in: ", user.uid);
        fetchUserProfile(user.uid).finally(() => setLoading(false));
      } else {
        console.log("No user is logged in");
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div className="loading"></div>;
  }
  return (
    <AuthContext.Provider value={{ user, handleSignOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
