// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.your-creations-container {
  padding: 20px;
  text-align: center;
}
.your-creations-title {
  font-family: "Fivo Sans Bold", sans-serif;
  font-size: 2em;
  text-align: center;
}
.build-cards-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.add-build-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.add-build-card:hover {
  background-color: #f9f9f9;
  border-color: #bbb;
}

.add-build-icon {
  font-size: 2em;
  color: #ccc;
}

.build-summary {
  margin-top: 10px;
  text-align: left;
}

.edit-build-name-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #5b9b36;
  margin-left: 10px;
}

.edit-build-name-button:hover {
  color: #5b9b36;
}

@media screen and (max-width: 768px) {
  .build-cards-container {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/YourCreations/YourCreations.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,yCAAyC;EACzC,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,oDAAoD;AACtD;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".your-creations-container {\n  padding: 20px;\n  text-align: center;\n}\n.your-creations-title {\n  font-family: \"Fivo Sans Bold\", sans-serif;\n  font-size: 2em;\n  text-align: center;\n}\n.build-cards-container {\n  display: flex;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.add-build-card {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 150px;\n  height: 150px;\n  border: 2px dashed #ccc;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s, border-color 0.3s;\n}\n\n.add-build-card:hover {\n  background-color: #f9f9f9;\n  border-color: #bbb;\n}\n\n.add-build-icon {\n  font-size: 2em;\n  color: #ccc;\n}\n\n.build-summary {\n  margin-top: 10px;\n  text-align: left;\n}\n\n.edit-build-name-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  color: #5b9b36;\n  margin-left: 10px;\n}\n\n.edit-build-name-button:hover {\n  color: #5b9b36;\n}\n\n@media screen and (max-width: 768px) {\n  .build-cards-container {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
