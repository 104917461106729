import React, { useEffect, useState } from "react";
import Card from "./Card"; // Import the Card component
import "./Card.css"; // Import the CSS file for styling
import "./FormContainer.css"; // Import the CSS file for form styling
import * as XLSX from "xlsx"; // Import the XLSX library
import { useAuth } from "../../AuthContext"; // Import the useAuth hook
import { backendURL } from "../../config"; // Import the backendURL from the config file
// const backend_url = process.env.REACT_APP_BACKEND_URL;
// const backend_url = "http://localhost:8080";
const DataSheetStep = ({
  onPrev,
  formData,
  updateFormData,
  buildId,
  buildName,
}) => {
  const { user } = useAuth(); // Get the user object from the useAuth hook
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [view, setView] = useState("grid");
  const [currentBuildName, setCurrentBuildName] = useState(buildName);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    console.log("Form Data: ", formData);
    console.log("User: ", user);
    console.log("Build Name: ", currentBuildName);
  }, [formData, user, currentBuildName]);

  const handlePrev = () => {
    onPrev();
  };

  const handleSave = () => {
    if (isSaved) {
      alert("Your build has already been saved");
      return;
    }
    if (!currentBuildName) {
      const name = prompt("Enter the name of the new build:");
      if (name) {
        setCurrentBuildName(name);
        if (buildId) {
          updateBuild(buildId, name);
        } else {
          saveToDatabase(name);
        }
      } else {
        console.error("Build name is required");
      }
    } else {
      if (buildId) {
        updateBuild(buildId, currentBuildName);
      } else {
        saveToDatabase(currentBuildName);
      }
    }
  };

  const saveToDatabase = async (name) => {
    try {
      const response = await fetch(`${backendURL}/saveBuild`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: user.uid,
          buildName: name,
          buildData: formData,
        }),
      });
      if (response.ok) {
        console.log("Build saved to database");
        alert("Your build has been saved successfully");
        setIsSaved(true);
      } else {
        console.error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error saving build to database: ", error);
    }
  };
  const updateBuild = async (id, name) => {
    try {
      const response = await fetch(`${backendURL}/updateBuild`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          buildId: id,
          buildName: name,
          buildData: formData,
        }),
      });
      if (response.ok) {
        console.log("Build updated in the database");
        alert("Your build has been updated successfully");
        setIsSaved(true);
      } else {
        console.error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error updating build in the database: ", error);
    }
  };
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    const addSheet = (title, data) => {
      const ws = XLSX.utils.json_to_sheet(
        data.map((item) => ({
          Type: title,
          Name: item.part_name,
          ...item.specs,
        }))
      );
      XLSX.utils.book_append_sheet(wb, ws, title);
    };

    if (formData.module?.length > 0) addSheet("LED Module", formData.module);
    if (formData.driver?.length > 0) addSheet("LED Driver", formData.driver);
    if (formData.accessories?.lens?.length > 0)
      addSheet("Lens", formData.accessories.lens);
    if (formData.accessories?.heatSink?.length > 0)
      addSheet("Heat Sink", formData.accessories.heatSink);
    if (formData.accessories?.connector?.length > 0)
      addSheet("Connector", formData.accessories.connector);

    XLSX.writeFile(wb, "DataSheet.xlsx");
  };

  const renderSpecs = (specs, exclude = []) => {
    if (typeof specs === "object") {
      return Object.entries(specs)
        .filter(([key]) => !exclude.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
    }
    return specs;
  };
  const columnOrders = {
    led_module: [
      "CCT",
      "CRI",
      "Lumens",
      "Power",
      "Current",
      "Voltage",
      "Efficiency",
      "Size",
      "Shape",
      "Material",
      "Supplier",
    ],
    led_driver: [
      "APL",
      "Type",
      "Power",
      "Output Current",
      "Output Voltage",
      "Input Voltage",
      "Dimming",
      "Programmable",
      "IP Rating",
      "Dimension",
      "Series",
      "Supplier",
    ],
    heatsink: [
      "part_name",
      "image",
      "APL",
      "Weight",
      "Material",
      "Dimension",
      "Thermal Resistance",
      "HEATSINK_CAD",
    ],
    lens: ["APL", "Beam", "Seal", "Material", "Dimension", "LENS_CAD"],
    connector: [
      "APL",
      "Type",
      "Current",
      "Voltage",
      "Material",
      "Dimension",
      "IP Rating",
    ],
  };

  const renderTable = (title, data, excludedColumns = []) => {
    const typeKey = title.toLowerCase().replace(" ", "_");
    console.log("Type Key: ", typeKey);
    const hasApl = data.some((item) =>
      item.specs.hasOwnProperty(
        "APL" || "apl" || "Apl" || "LENS_APL" || "CANNECTOR_APL"
      )
    );
    const columns = columnOrders[typeKey];
    if (!columns) {
      return null;
    }

    return (
      <div className="datasheet-container">
        <h2 className="datasheet-table-title">{title}</h2>
        <div className="datasheet-table-container">
          <table className="datasheet-table">
            <thead>
              <tr>
                <th>Name</th>
                {hasApl && <th>APL</th>}
                {columns
                  .filter(
                    (column) =>
                      data.some((item) => item.specs[column]) &&
                      !excludedColumns.includes(column)
                  )
                  .map((column) => (
                    <th key={column}>{column}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.part_name}</td>
                  {hasApl && (
                    <td>
                      {item.specs.APL || item.specs.apl || item.specs.Apl}
                    </td>
                  )}
                  {columns
                    .filter(
                      (column) =>
                        item.specs[column] !== undefined &&
                        !excludedColumns.includes(column)
                    )
                    .map((column) => (
                      <td key={column} className="specs-column">
                        {item.specs[column]}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const excludedColumns = [
    "specs",
    "class2",
    "classp",
    "FK_Market",
    "LENS_CAD",
    "FK_MARKET",
    "FK_MODULES",
    "Application",
    "FK_Application",
    "HEATSINK_CAD",
    "FK_APPLICATION",
    "compatible_with",
    "application",
    "FK_MODULES.1",
    "APL",
    " FK_Application",
    "Channel",
    "CONNECTOR_CATEGORY",
    "CANNECTOR_APL",
    "CONNECTOR_PNUMBER",
  ];

  return (
    <div className="step-container">
      <div className="header-container">
        <h2 className="header-title">Your Selections</h2>
        <div className="button-container">
          <button
            className={`nav-button-save ${isSaved ? "disabled" : ""}`}
            onClick={handleSave}
            disabled={isSaved} // Disable the button if the build is saved
          >
            {isSaved ? (
              <>
                Saved <i className="fas fa-check icon"></i>
              </>
            ) : (
              <>
                Save Build <i className="fas fa-save icon"></i>
              </>
            )}
          </button>
          <button className="nav-button-excel" onClick={exportToExcel}>
            Export as Data <i className="fas fa-file-excel icon"></i>
          </button>
        </div>
      </div>
      <div className="view-toggle">
        <button onClick={() => setView("grid")} className="icon-button">
          <i className="fas fa-th-large"></i>
        </button>
        <span className="pipe-separator">|</span>
        <button onClick={() => setView("list")} className="icon-button">
          <i className="fas fa-th-list"></i>
        </button>
      </div>
      <div className="summary">
        {view === "grid" ? (
          <div className="card-container">
            {formData.module?.length > 0 ? (
              formData.module?.map((module) => (
                <Card
                  key={module.id}
                  image={module.image || "https://via.placeholder.com/150"}
                  name={module.part_name}
                  specs={renderSpecs(module.specs, excludedColumns)}
                  tag={"LED Module"}
                />
              ))
            ) : (
              <p>No LED Modules selected</p>
            )}

            {formData.driver?.map((driver) => (
              <Card
                key={driver.id}
                image={driver.image || "https://via.placeholder.com/150"}
                name={driver.part_name}
                specs={renderSpecs(driver.specs, excludedColumns)}
                tag={"LED Driver"}
              />
            ))}
            {formData.accessories?.lens?.map((optic) => (
              <Card
                key={optic.id}
                image={optic.image || "https://via.placeholder.com/150"}
                name={optic.part_name}
                specs={renderSpecs(optic.specs, excludedColumns)}
                tag={"Lens"}
              />
            ))}
            {formData.accessories?.heatSink?.map((heatSink) => (
              <Card
                key={heatSink.id}
                image={heatSink.image || "https://via.placeholder.com/150"}
                name={heatSink.part_name}
                specs={renderSpecs(heatSink.specs, excludedColumns)}
                tag={"Heatsink"}
              />
            ))}
            {formData.accessories?.connector?.map((connector) => (
              <Card
                key={connector.id}
                image={connector.image || "https://via.placeholder.com/150"}
                name={connector.part_name}
                specs={renderSpecs(connector.specs, excludedColumns)}
                tag={"Connector"}
              />
            ))}
          </div>
        ) : (
          <>
            {formData.module?.length > 0 &&
              renderTable("LED Module", formData.module, excludedColumns)}
            {formData.driver?.length > 0 &&
              renderTable("LED Driver", formData.driver, excludedColumns)}
            {formData.accessories?.lens?.length > 0 &&
              renderTable("Lens", formData.accessories?.lens, excludedColumns)}
            {formData.accessories?.heatSink?.length > 0 &&
              renderTable(
                "Heatsink",
                formData.accessories?.heatSink,
                excludedColumns
              )}
            {formData.accessories?.connector?.length > 0 &&
              renderTable(
                "Connector",
                formData.accessories?.connector,
                excludedColumns
              )}
          </>
        )}
      </div>
      <div className="navigation-buttons">
        <button className="nav-button-prev" onClick={handlePrev}>
          <i className="fas fa-arrow-left"></i>
        </button>
      </div>
    </div>
  );
};

export default DataSheetStep;
