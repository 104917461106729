import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./AuthContext";
import Header from "./pages/Header";
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import "./App.css";
import SystemCreator from "./pages/SystemCreator/SystemCreator";
import YourCreations from "./pages/YourCreations";
// import ModuleCalculator from "./pages/ModuleCalculator/ModuleCalculator";
import BrowseCollection from "./pages/BrowseCollection/BrowseCollection";
import CategoryTable from "./components/BrowseCollection/CategoryTable";
import MultiCalculatorView from "./pages/ModuleCalculator/MultiCalculatorView";
import Footer from "./pages/Footer/Footer";
import Hotjar from "@hotjar/browser";

const App = () => {
  useEffect(() => {
    const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
    Hotjar.init(siteId, hotjarVersion);
  }, []);
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

const AppContent = () => {
  const { user } = useAuth();
  console.log("User in AppContent: ", user);
  return (
    <>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/module-calculator"
              element={
                user ? <MultiCalculatorView /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/create"
              element={user ? <SystemCreator /> : <Navigate to="/login" />}
            />
            <Route
              path="/your-creations"
              element={user ? <YourCreations /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/create-account"
              element={!user ? <SignUp /> : <Navigate to="/" />}
            />
            {user && user.userType === "admin" && (
              <>
                <Route
                  path="/browse-collection"
                  element={<BrowseCollection />}
                />
                <Route path="/category/:category" element={<CategoryTable />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;
