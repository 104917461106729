// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lsc-title {
  font-family: "Fivo Sans Bold", sans-serif;
  font-size: 2em;
  text-align: center;
}
.StartOverButton {
  padding: 0.7em 1.625em;
  width: 10em;
  font-size: 1em;
  background-color: #d23038;
  color: white;
  border: 2px solid #c1272d;
  border-radius: 1.875em;
  cursor: pointer;
  text-transform: capitalize;
  font-family: "Fivo Sans Medium", sans-serif;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.StartOverButton:hover {
  background-color: #c1272d;
}
.center-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1.25em 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SystemCreator/SystemCreator.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,0BAA0B;EAC1B,2CAA2C;EAC3C,kBAAkB;EAClB,cAAc;EACd,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB","sourcesContent":[".lsc-title {\n  font-family: \"Fivo Sans Bold\", sans-serif;\n  font-size: 2em;\n  text-align: center;\n}\n.StartOverButton {\n  padding: 0.7em 1.625em;\n  width: 10em;\n  font-size: 1em;\n  background-color: #d23038;\n  color: white;\n  border: 2px solid #c1272d;\n  border-radius: 1.875em;\n  cursor: pointer;\n  text-transform: capitalize;\n  font-family: \"Fivo Sans Medium\", sans-serif;\n  text-align: center;\n  display: block;\n  margin: 0 auto;\n}\n.StartOverButton:hover {\n  background-color: #c1272d;\n}\n.center-button-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 1.25em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
