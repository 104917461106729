import React, { useState } from "react";
import "./BulkEditModal.css"; // Create a separate CSS file for styling

const BulkEditModal = ({ columns, onApply, onClose }) => {
  const [selectedColumn, setSelectedColumn] = useState("");
  const [newValue, setNewValue] = useState("");

  const handleApply = () => {
    onApply(selectedColumn, newValue);
    onClose();
  };

  return (
    <div className="bulk-edit-modal-overlay">
      <div className="bulk-edit-modal">
        <h2>Bulk Edit</h2>
        <div className="bulk-edit-modal-input-container">
          <select
            value={selectedColumn}
            onChange={(e) => setSelectedColumn(e.target.value)}
          >
            <option value="">Select a Column</option>
            {columns.map((col) => (
              <option key={col} value={col}>
                {col}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            placeholder="New Value"
          />
        </div>

        <div className="bulk-edit-buttons">
          <button onClick={handleApply}>Apply</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default BulkEditModal;
