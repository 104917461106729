// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProgressBar.css */
.progress-bar {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0df;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Fivo Sans Bold", sans-serif;
  font-size: 0.8em;
  color: #333;
  cursor: pointer;
}

.step.completed {
  background-color: #b51f25;
  color: white;
}
.step.active {
  border: 2px solid #b51f25;
  background-color: #e0e0df;
}
`, "",{"version":3,"sources":["webpack://./src/components/SystemCreator/ProgressBar.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":["/* src/components/ProgressBar.css */\n.progress-bar {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  margin: 20px 0;\n}\n\n.step {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-color: #e0e0df;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: \"Fivo Sans Bold\", sans-serif;\n  font-size: 0.8em;\n  color: #333;\n  cursor: pointer;\n}\n\n.step.completed {\n  background-color: #b51f25;\n  color: white;\n}\n.step.active {\n  border: 2px solid #b51f25;\n  background-color: #e0e0df;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
