import React, { useState } from "react";
import PropTypes from "prop-types";
import emailjs from "emailjs-com";
import "./BuildCard.css";
import { useAuth } from "../../AuthContext";
import CustomContextMenu from "./CustomContextMenu";
import { FaCheckCircle, FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const BuildCard = ({ build, onEditName, onClick, onDelete }) => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(build?.buildName || "");
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [message, setMessage] = useState("");
  const [quoteRequestSent, setQuoteRequestSent] = useState(
    localStorage.getItem(`quoteRequestSent_${build.buildId}`) === "true"
  );
  //   console.log("Context Menu Visible: ", contextMenuVisible); // Debugging line
  //   console.log("Context Menu Position: ", contextMenuPosition); // Debugging line

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setContextMenuVisible(false);
  };

  const handleNameSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onEditName(build.buildId, newName);
    setIsEditing(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(build.buildId);
    setContextMenuVisible(false);
  };
  const handleRightClick = (e) => {
    e.preventDefault();

    setContextMenuPosition({ x: e.pageX, y: e.pageY });
    setContextMenuVisible(true);
  };
  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleGetQuoteClick = async (e) => {
    e.stopPropagation();

    if (quoteRequestSent) {
      alert("You have already sent a quote request for this build.");
      return;
    }

    const templateParams = {
      to_email: "sales2@adurasolutions.com",
      user_email: user.company_email,
      user_name: user.full_name, // Assuming displayName is available in the auth context
      build_name: build.buildName,
      module: build.buildData.module?.map((m) => m.part_name).join(", ") || "",
      driver: build.buildData.driver?.map((d) => d.part_name).join(", ") || "",
      heatsink:
        build.buildData.accessories?.heatSink
          ?.map((h) => h.part_name)
          .join(", ") || "",
      lens:
        build.buildData.accessories?.lens?.map((l) => l.part_name).join(", ") ||
        "",
      connector:
        build.buildData.accessories?.connector
          ?.map((c) => c.part_name)
          .join(", ") || "",
      message: message || "",
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_SALES_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );
      alert("Quote request sent successfully");

      // Set quoteRequestSent to true in localStorage
      setQuoteRequestSent(true);
      localStorage.setItem(`quoteRequestSent_${build.buildId}`, "true");
    } catch (error) {
      console.error("Error sending quote request:", error);
      alert("Error sending quote request");
    }
  };
  const handleTextareaClick = (e) => {
    e.stopPropagation();
  };
  console.log("BuildCard.js: build.buildData: ", build.buildData); // Debugging line
  //   const buildDataKeys = ["module", "driver", "accessories"];
  const createdAtFormatted = new Date(build.createdAt).toLocaleDateString();
  return (
    <div>
      <CustomContextMenu
        visible={contextMenuVisible}
        position={contextMenuPosition}
        onClose={handleCloseContextMenu}
        items={[
          {
            label: "Edit",
            onClick: handleEditClick,
            icon: <FaPencilAlt />,
            color: "#007bff",
          },
          {
            label: "Delete",
            onClick: handleDeleteClick,
            icon: <FaTrashAlt />,
            color: "#dc3545",
          },
        ]}
      />
      <div
        className="build-card"
        onClick={() => onClick(build)}
        onContextMenu={handleRightClick}
      >
        <div className="build-card-header">
          {isEditing ? (
            <form onSubmit={handleNameSubmit}>
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                className="edit-build-name-input"
              />
              <button type="submit" className="save-name-button">
                <i className="fas fa-save"></i>
              </button>
            </form>
          ) : (
            <div className="build-card-title-header">
              <h3 className="build-card-title">{build.buildName}</h3>
            </div>
          )}
        </div>
        <div className="market-image-container">
          <img
            src={build.buildData.market?.image}
            alt={build.buildData.market?.name}
            className="market-image"
          />
          <button className="edit-build-button" onClick={handleGetQuoteClick}>
            {quoteRequestSent ? (
              <FaCheckCircle className="quote-sent-icon" />
            ) : (
              "Get a Quote"
            )}
          </button>
        </div>
        <div className="build-summary">
          <div className="build-summary-item">
            <strong className="build_creation-date">Created At:</strong>{" "}
            {createdAtFormatted}
          </div>
          {quoteRequestSent && (
            <div className="build-summary-item quote-status">
              Quote Request Sent
            </div>
          )}
        </div>
        {!quoteRequestSent && (
          <div className="quote-message-container">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Add a message for your quote request (optional)"
              className="quote-message-textarea"
              onClick={handleTextareaClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

BuildCard.propTypes = {
  build: PropTypes.shape({
    buildId: PropTypes.number.isRequired,
    buildName: PropTypes.string.isRequired,
    buildData: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onEditName: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BuildCard;
