// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection-container {
  padding: 20px;
}

.collection-header {
  font-family: "Fivo Sans Medium", sans-serif;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.category-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
}

.category-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 200px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.category-image {
  position: relative;
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 0.625em;
}

.category-name {
  font-family: "Fivo Sans Regular", sans-serif;
  font-size: 1.2em;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/pages/BrowseCollection/BrowseCollection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,2CAA2C;EAC3C,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,4CAA4C;EAC5C,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".collection-container {\n  padding: 20px;\n}\n\n.collection-header {\n  font-family: \"Fivo Sans Medium\", sans-serif;\n  font-size: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 30px;\n}\n\n.category-cards {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2em;\n  justify-content: center;\n}\n\n.category-card {\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: transform 0.3s, box-shadow 0.3s;\n  width: 200px;\n  text-align: center;\n  background-color: #fff;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.category-card:hover {\n  transform: scale(1.05);\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.category-image {\n  position: relative;\n  width: 100%;\n  height: 150px;\n  object-fit: contain;\n  margin-bottom: 0.625em;\n}\n\n.category-name {\n  font-family: \"Fivo Sans Regular\", sans-serif;\n  font-size: 1.2em;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
