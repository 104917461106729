import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EnvironmentStep from "../../components/SystemCreator/EnvironmentStep";
import MarketStep from "../../components/SystemCreator/MarketStep";
import ModuleClass from "../../components/SystemCreator/ModuleClassStep";
import PCBStep from "../../components/SystemCreator/PCBStep";
import DriverStep from "../../components/SystemCreator/DriverStep";
import AccessoryStep from "../../components/SystemCreator/AccessoryStep";
import DataSheetStep from "../../components/SystemCreator/DataSheetStep";
import ProgressBar from "../../components/SystemCreator/ProgressBar";
import "../../components/SystemCreator/Card.css";
import "./SystemCreator.css";
const steps = [
  "Environment",
  "Market",
  "ModuleClass",
  "PCB",
  "Driver",
  "Accessories",
  "Data Sheet",
];

const SystemCreator = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          environment: null,
          market: null,
          moduleClass: null,
          accessories: {
            lens: null,
            heatSink: null,
            connector: null,
          },
          driver: null,
        };
  });

  const buildId = state?.buildId || null;
  const buildName = state?.buildName || "";

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const updateFormData = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };
  useEffect(() => {
    if (!buildId) {
      clearStorage();
    }
  }, [buildId]);
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep) {
      // Clear form data for all steps beyond the current step
      const newFormData = { ...formData };
      if (stepIndex <= 2) {
        // Clear from ModuleClassStep onward
        newFormData.moduleClass = null;
        newFormData.module = null;
        newFormData.accessories = {
          lens: null,
          heatSink: null,
          connector: null,
        };
        newFormData.driver = null;
      }
      if (stepIndex === 1) {
        // Clear from MarketStep onward
        newFormData.market = null;
      }
      setFormData(newFormData);
      setCurrentStep(stepIndex);
    } else {
      alert("Please complete the current step before proceeding.");
    }
  };

  const clearStorage = () => {
    localStorage.removeItem("formData");
    setFormData({
      environment: null,
      market: null,
      moduleClass: null,
      accessories: {
        lens: null,
        heatSink: null,
        connector: null,
      },
      driver: null,
    });
    setCurrentStep(0);
  };

  const handleStartNewBuild = () => {
    clearStorage();
    navigate("/create");
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <EnvironmentStep
            onNext={nextStep}
            updateFormData={updateFormData}
            selectedEnvironment={formData.environment}
          />
        );
      case 1:
        return (
          <MarketStep
            onNext={nextStep}
            onPrev={prevStep}
            environment={formData.environment}
            updateFormData={updateFormData}
          />
        );
      case 2:
        return (
          <ModuleClass
            onNext={nextStep}
            onPrev={prevStep}
            updateFormData={updateFormData}
            selectedClass={formData.moduleClass}
          />
        );
      case 3:
        return (
          <PCBStep
            onNext={nextStep}
            onPrev={prevStep}
            updateFormData={updateFormData}
            selectedClass={formData.moduleClass}
            selectedPCB={formData.module}
          />
        );
      case 4:
        return (
          <DriverStep
            onNext={nextStep}
            onPrev={prevStep}
            updateFormData={updateFormData}
            environment={formData.environment}
          />
        );
      case 5:
        return (
          <AccessoryStep
            onNext={nextStep}
            onPrev={prevStep}
            updateFormData={updateFormData}
            selectedComponents={formData}
          />
        );
      case 6:
        return (
          <DataSheetStep
            onPrev={prevStep}
            formData={formData}
            updateFormData={updateFormData}
            buildId={buildId}
            buildName={buildName}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="system-creator">
      <h1 className="lsc-title">Lighting System Creator</h1>
      <ProgressBar
        step={currentStep + 1}
        totalSteps={steps.length}
        onStepClick={handleStepClick}
      />
      <div className="center-button-container">
        <button className="StartOverButton" onClick={handleStartNewBuild}>
          Start Over
        </button>
      </div>

      {renderStep()}
    </div>
  );
};

export default SystemCreator;
