// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  z-index: 1000;
}

.auth-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
  font-family: "Fivo Sans Light", sans-serif;
  color: #000;
}

.auth-timer-bar {
  height: 5px;
  background: #c1272d;
  animation: shrink 5s linear forwards;
  margin-top: 10px;
}

@keyframes shrink {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,QAAQ;EACV;AACF","sourcesContent":[".auth-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding-top: 20px;\n  z-index: 1000;\n}\n\n.auth-modal-content {\n  background-color: #ffffff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  width: 80%;\n  max-width: 400px;\n  text-align: center;\n  font-family: \"Fivo Sans Light\", sans-serif;\n  color: #000;\n}\n\n.auth-timer-bar {\n  height: 5px;\n  background: #c1272d;\n  animation: shrink 5s linear forwards;\n  margin-top: 10px;\n}\n\n@keyframes shrink {\n  from {\n    width: 100%;\n  }\n  to {\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
