import React, { useState } from "react";
import PropTypes from "prop-types";
import "./BuildSidebar.css";
import * as XLSX from "xlsx";

const excludedColumns = [
  "specs",
  "class2",
  "classp",
  "FK_Market",
  "LENS_CAD",
  "FK_MARKET",
  "FK_MODULES",
  "Application",
  "FK_Application",
  "HEATSINK_CAD",
  "FK_APPLICATION",
  "compatible_with",
  "application",
  "FK_MODULES.1",
  "APL",
  " FK_Application",
  "Channel",
  "CONNECTOR_CATEGORY",
  "CANNECTOR_APL",
  "CONNECTOR_PNUMBER",
  "Supplier",
  "Series",
  "Lenses Options",
];

const BuildSidebar = ({ build, onClose }) => {
  const [expandedTables, setExpandedTables] = useState(() => {
    const keys = ["module", "driver", "heatSink", "connector", "lens"];
    return keys.reduce((acc, key) => {
      acc[key] = true; // Set initial state to expanded
      return acc;
    }, {});
  });

  const toggleAccordion = (key) => {
    setExpandedTables((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const lookupKeys = {
    module: "Modules",
    driver: "Drivers",
    heatSink: "Heatsinks",
    connector: "Connectors",
    lens: "Lenses",
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    const addSheet = (title, data) => {
      const filteredData = data.map((item) => {
        const filteredSpecs = Object.entries(item.specs)
          .filter(([key]) => !excludedColumns.includes(key))
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});

        return {
          Type: title,
          Name: item.part_name,
          ...filteredSpecs,
        };
      });

      const ws = XLSX.utils.json_to_sheet(filteredData);
      XLSX.utils.book_append_sheet(wb, ws, title);
    };

    const buildData = build.buildData;

    if (buildData.module?.length > 0) addSheet("LED Module", buildData.module);
    if (buildData.driver?.length > 0) addSheet("LED Driver", buildData.driver);
    if (buildData.accessories?.lens?.length > 0)
      addSheet("Lens", buildData.accessories.lens);
    if (buildData.accessories?.heatSink?.length > 0)
      addSheet("Heat Sink", buildData.accessories.heatSink);
    if (buildData.accessories?.connector?.length > 0)
      addSheet("Connector", buildData.accessories.connector);

    XLSX.writeFile(wb, `${build.buildName}_DataSheet.xlsx`);
  };

  const renderTableList = (data) => {
    return Object.keys(lookupKeys).map((key) => {
      const hasComponents =
        (key in data && data[key].length > 0) ||
        (data.accessories &&
          key in data.accessories &&
          data.accessories[key].length > 0);

      return hasComponents ? (
        <div className="accordion-item" key={key}>
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(key)}
          >
            <span>{lookupKeys[key]}</span>
            <i
              className={`fas fa-chevron-down icon ${
                expandedTables[key] ? "rotate-icon" : ""
              }`}
            ></i>
          </div>
          {expandedTables[key] && (
            <div className="accordion-body show">
              {renderTableDetails(data, key)}
            </div>
          )}
        </div>
      ) : (
        <div className="accordion-item" key={key}>
          <button
            className="add-button"
            onClick={() => (window.location.href = `/add-${key}`)}
          >
            + Add {lookupKeys[key]}
          </button>
        </div>
      );
    });
  };

  const renderTableDetails = (tableData, key) => {
    if (key in tableData) {
      tableData = tableData[key];
    } else if (key in tableData.accessories) {
      tableData = tableData.accessories[key];
    }

    return (
      <div className="table-wrapper">
        <table className="sidebar-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              {tableData.length > 0 &&
                Object.keys(tableData[0].specs)
                  .filter((column) => !excludedColumns.includes(column))
                  .map((column) => <th key={column}>{column}</th>)}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(tableData) ? (
              tableData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={item.image}
                        alt={item.part_name}
                        className="sidebar-table-image"
                      />
                    </a>
                  </td>
                  <td>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.part_name}
                    </a>
                  </td>
                  {Object.entries(item.specs)
                    .filter(([key]) => !excludedColumns.includes(key))
                    .map(([key, value]) => (
                      <td key={key} className="specs-column">
                        {value}
                      </td>
                    ))}
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <a
                    href={tableData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={tableData.image}
                      alt={tableData.part_name}
                      className="sidebar-table-image"
                    />
                  </a>
                </td>
                <td>
                  <a
                    href={tableData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tableData.part_name}
                  </a>
                </td>
                {Object.entries(tableData.specs)
                  .filter(([key]) => !excludedColumns.includes(key))
                  .map(([key, value]) => (
                    <td key={key} className="specs-column">
                      {value}
                    </td>
                  ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="build-sidebar">
      <div className="sidebar-header">
        <h3>{build.buildName}</h3>
        <button className="close-sidebar-button" onClick={onClose}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="sidebar-actions">
        <button className="export-excel-button" onClick={exportToExcel}>
          Export to Excel <i className="fas fa-file-excel"></i>
        </button>
      </div>
      <div className="build-details">{renderTableList(build.buildData)}</div>
    </div>
  );
};

BuildSidebar.propTypes = {
  build: PropTypes.shape({
    buildId: PropTypes.string.isRequired,
    buildName: PropTypes.string.isRequired,
    buildData: PropTypes.object.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BuildSidebar;
