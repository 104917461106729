import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Card.css";
import { backendURL } from "../../config";

const DriverStep = ({
  onNext,
  onPrev,
  updateFormData,
  selectedDrivers,
  environment,
}) => {
  const [filters, setFilters] = useState({
    dimming: "",
    type: "",
    programmable: "",
    output_current: "",
    output_voltage: "",
    driver_supplier: "",
  });
  const [localSelectedDrivers, setLocalSelectedDrivers] = useState(
    selectedDrivers || []
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allDrivers, setAllDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    dimming: [],
    type: [],
    programmable: [],
    output_current: [],
    output_voltage: [],
    driver_supplier: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const driversPerPage = 24;

  useEffect(() => {
    setLocalSelectedDrivers(selectedDrivers || []);
  }, [selectedDrivers]);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await fetch(`${backendURL}/drivers`);
        let data = await response.json();

        setAllDrivers(data);
        setFilteredDrivers(data); // Initialize filtered drivers with all data

        // Set initial filter options
        updateFilterOptions(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDrivers();
  }, [environment]);

  useEffect(() => {
    // Filter drivers based on the filters
    const filtered = allDrivers.filter((driver) => {
      return (
        (!filters.dimming || driver.specs.Dimming === filters.dimming) &&
        (!filters.type || driver.specs.Type === filters.type) &&
        (!filters.programmable ||
          driver.specs.Programmable === filters.programmable) &&
        (!filters.output_current ||
          driver.specs["Output Current"] === filters.output_current) &&
        (!filters.output_voltage ||
          driver.specs["Output Voltage"] === filters.output_voltage) &&
        (!filters.driver_supplier ||
          driver.specs.Supplier === filters.driver_supplier)
      );
    });
    setFilteredDrivers(filtered);
    updateFilterOptions(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  }, [filters, allDrivers]);

  const updateFilterOptions = (data) => {
    const dimming = [...new Set(data.map((driver) => driver.specs.Dimming))];
    const type = [...new Set(data.map((driver) => driver.specs.Type))];
    const programmable = [
      ...new Set(data.map((driver) => driver.specs.Programmable)),
    ];
    const output_current = [
      ...new Set(data.map((driver) => driver.specs["Output Current"])),
    ];
    const output_voltage = [
      ...new Set(data.map((driver) => driver.specs["Output Voltage"])),
    ];
    const driver_supplier = [
      ...new Set(data.map((driver) => driver.specs.Supplier)),
    ];

    setFilterOptions({
      dimming,
      type,
      programmable,
      output_current,
      output_voltage,
      driver_supplier,
    });
  };

  const handlePrev = () => {
    onPrev();
  };
  const handleNext = () => {
    if (localSelectedDrivers.length > 0) {
      const selectedDriverData = allDrivers.filter((driver) =>
        localSelectedDrivers.includes(driver.id)
      );
      updateFormData("driver", selectedDriverData);
      onNext();
    } else {
      alert("Please select at least one driver before proceeding.");
    }
  };

  const handleCardClick = (driverId) => {
    setLocalSelectedDrivers((prevSelectedDrivers) => {
      if (prevSelectedDrivers.includes(driverId)) {
        return prevSelectedDrivers.filter((id) => id !== driverId);
      } else {
        return [...prevSelectedDrivers, driverId];
      }
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Pagination logic
  const indexOfLastDriver = currentPage * driversPerPage;
  const indexOfFirstDriver = indexOfLastDriver - driversPerPage;
  const currentDrivers = filteredDrivers.slice(
    indexOfFirstDriver,
    indexOfLastDriver
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="step-container">
      <h2 className="driver-title">Select Your LED Driver</h2>
      <div className="filters">
        <label>
          Dimming:
          <select
            name="dimming"
            value={filters.dimming}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.dimming.map((dimming) => (
              <option key={dimming} value={dimming}>
                {dimming}
              </option>
            ))}
          </select>
        </label>
        <label>
          Type:
          <select
            name="type"
            value={filters.type}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.type.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
        <label>
          Programmable:
          <select
            name="programmable"
            value={filters.programmable}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.programmable.map((programmable) => (
              <option key={programmable} value={programmable}>
                {programmable}
              </option>
            ))}
          </select>
        </label>
        <label>
          Output Current:
          <select
            name="output_current"
            value={filters.output_current}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.output_current.map((output_current) => (
              <option key={output_current} value={output_current}>
                {output_current}
              </option>
            ))}
          </select>
        </label>
        <label>
          Output Voltage:
          <select
            name="output_voltage"
            value={filters.output_voltage}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.output_voltage.map((output_voltage) => (
              <option key={output_voltage} value={output_voltage}>
                {output_voltage}
              </option>
            ))}
          </select>
        </label>
        <label>
          Driver Supplier:
          <select
            name="driver_supplier"
            value={filters.driver_supplier}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {filterOptions.driver_supplier.map((driver_supplier) => (
              <option key={driver_supplier} value={driver_supplier}>
                {driver_supplier}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="results-info">
        {filteredDrivers.length > 0 ? (
          <p>
            Showing {filteredDrivers.length} out of {allDrivers.length} drivers
          </p>
        ) : (
          <p>No results found</p>
        )}
        <p>Selected: {localSelectedDrivers.length}</p>
      </div>
      <div className="card-container">
        {currentDrivers.map((driver) => {
          const specs = [
            `${driver.specs.Power} W`,
            `${driver.specs["Output Current"]} mA`,
            `${driver.specs.Dimming}`,
          ].join(" | ");

          const allSpecs = [
            `Power: ${driver.specs.Power} W`,
            `Current: ${driver.specs["Output Current"]} mA`,
            `Dimming: ${driver.specs.Dimming}`,
            `Type: ${driver.specs.Type}`,
            `Programmable: ${driver.specs.Programmable}`,
            `Series: ${driver.specs.Series}`,
            `Supplier: ${driver.specs.Supplier}`,
          ].join(", ");

          return (
            <div key={driver.id} onClick={() => handleCardClick(driver.id)}>
              <Card
                image={driver.image || "https://via.placeholder.com/150"}
                name={driver.part_name}
                specs={allSpecs}
                topSpecs={specs}
                isSelected={localSelectedDrivers.includes(driver.id)}
              />
            </div>
          );
        })}
      </div>
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(filteredDrivers.length / driversPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
      <div className="navigation-buttons">
        <button className="nav-button-prev" onClick={handlePrev}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          className="nav-button-next"
          onClick={handleNext}
          disabled={localSelectedDrivers.length === 0}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default DriverStep;
