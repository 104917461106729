import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import CustomDropdown from "../../components/ModuleCalculator/CustomDropdown"; // Adjust the import path as necessary
import "./ModuleCalculator.css";
import { CiCircleMinus } from "react-icons/ci";

const ModuleCalculator = ({ index, onUpdate, onRemove, showCancelButton }) => {
  const [items, setItems] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [data, setData] = useState({
    modulesPerDriver: "",
    driverCurrent: "",
    numOfStrings: "",
    ledsPerString: "",
    driverEfficiency: 0.9, // 90%
    opticalEfficiency: 0.9, // 90%
  });
  const [selectedPart, setSelectedPart] = useState(null);
  const [selectedCRI, setSelectedCRI] = useState("");
  const [selectedCCT, setSelectedCCT] = useState("");
  const [selectedTc, setSelectedTc] = useState("");
  const [selectedCurrent, setSelectedCurrent] = useState("");
  const [percentErrorMessage, setPercentErrorMessage] = useState("");
  const [currentErrorMessage, setCurrentErrorMessage] = useState("");
  const [currentRange, setCurrentRange] = useState({ min: 0, max: 0 });

  const [setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    // Load and parse Reference Details CSV
    Papa.parse(`${process.env.PUBLIC_URL}/Reference_Details.csv`, {
      download: true,
      header: true,
      complete: (results) => {
        console.log("Reference Details CSV data:", results.data);
        setItems(results.data);
      },
      error: (error) =>
        console.error("Error loading Reference Details CSV:", error),
    });

    // Load Product Details CSV
    Papa.parse(`${process.env.PUBLIC_URL}/LM281B_plus_PRO.csv`, {
      download: true,
      header: true,
      complete: (results) => {
        console.log("Product Details CSV data:", results.data); // Log the parsed Product Details data
        setProductDetails(results.data);
      },
    });

    // Add event listener to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsDropdownOpen]);

  // Compute derived values
  const totalModuleQty = parseFloat(data.modulesPerDriver || "0") * 1 || 0;
  const qtyOfLeds =
    parseInt(data.numOfStrings || "0", 10) *
      parseInt(selectedPart?.["LEDs/String"] || "0", 10) || 0;

  // PCB performance values
  const productDetail = productDetails.find(
    (item) =>
      item["CCT"] === selectedCCT &&
      item["CRI"] === selectedCRI &&
      parseFloat(item["Ts"]) === parseFloat(selectedTc) && // Ensure numeric comparison
      parseFloat(item["Current [A]"]) === parseFloat(selectedCurrent) // Ensure numeric comparison
  );

  const singleLedVoltage = productDetail
    ? parseFloat(productDetail["VF [V]"] || "0")
    : 0;
  const singleLedFlux = productDetail
    ? parseFloat(productDetail["Flux [㏐]"] || "0")
    : 0;
  const singleLedPower = productDetail
    ? parseFloat(productDetail["Power [W]"] || "0")
    : 0;
  const moduleVoltage =
    singleLedVoltage * (parseInt(data.ledsPerString || "0", 10) || 0);
  const singleModuleCurrent =
    parseFloat(data.driverCurrent || "0") /
      parseFloat(data.modulesPerDriver || "1") || 0;
  const moduleLumens = singleLedFlux * qtyOfLeds || 0;
  const modulePower = singleLedPower * qtyOfLeds || 0;
  const moduleEfficiency = moduleLumens / modulePower || 0;

  // Call onUpdate to update the parent component with the latest values
  useEffect(() => {
    onUpdate(index, {
      totalModuleQty,
      modulePower,
      moduleLumens,
      driverEfficiency: data.driverEfficiency,
      opticalEfficiency: data.opticalEfficiency,
      selectedPart,
      selectedCRI,
      selectedCCT,
      selectedTc,
      selectedCurrent,
      data,
      singleLedVoltage,
      singleLedFlux,
      moduleVoltage,
      singleModuleCurrent,
      moduleEfficiency,
    });

    // Debugging logs
    // console.log("Total Module Quantity:", totalModuleQty);
    // console.log("Quantity of LEDs:", qtyOfLeds);
    // console.log("Single LED Voltage (V):", singleLedVoltage);
    // console.log("Single LED Flux (lm):", singleLedFlux);
    // console.log("Module Voltage (V):", moduleVoltage);
    // console.log("Single Module Current (A):", singleModuleCurrent);
    // console.log("Module Lumens (lm):", moduleLumens);
    // console.log("Module Power (W):", modulePower);
    // console.log("Module Efficiency (lm/W):", moduleEfficiency);
  }, [
    totalModuleQty,
    modulePower,
    moduleLumens,
    data.driverEfficiency,
    data.opticalEfficiency,
    index,
    onUpdate,
    selectedPart,
    selectedCRI,
    selectedCCT,
    selectedTc,
    selectedCurrent,
    data,
    singleLedVoltage,
    singleLedFlux,
    moduleVoltage,
    singleModuleCurrent,
    moduleEfficiency,
  ]);

  const handlePartChange = (part) => {
    if (!part) {
      setSelectedPart(null);
      setData((prevData) => ({
        ...prevData,
        numOfStrings: "",
        ledsPerString: "",
      }));
      setCurrentRange({ min: 0, max: 0 });
      return;
    }

    console.log("Selected part:", part);

    setSelectedPart(part);
    setData((prevData) => {
      const numOfStrings = parseInt(part?.Strings || "0", 10);
      const ledsPerString = parseInt(part?.["LEDs/String"] || "0", 10);
      const modulesPerDriver = parseFloat(prevData.modulesPerDriver || "0");
      const newNumOfStrings = numOfStrings * modulesPerDriver;

      const minCurrent = (
        parseFloat(part["Min Range (A)"]) * modulesPerDriver
      ).toFixed(2);
      const maxCurrent = (
        parseFloat(part["Max Range (A)"]) * modulesPerDriver
      ).toFixed(2);
      setCurrentRange({
        min: minCurrent,
        max: maxCurrent,
      });

      // Ensure the driver current is within the new range
      let adjustedDriverCurrent = prevData.driverCurrent;
      if (
        adjustedDriverCurrent < minCurrent ||
        adjustedDriverCurrent > maxCurrent
      ) {
        setCurrentErrorMessage(
          `Driver current adjusted to be within the range ${minCurrent}A - ${maxCurrent}A.`
        );
      } else {
        setCurrentErrorMessage("");
      }

      return {
        ...prevData,
        numOfStrings: newNumOfStrings,
        ledsPerString,
        driverCurrent: adjustedDriverCurrent,
      };
    });
  };

  const handleDecimalChange = (key) => (e) => {
    const value = parseFloat(e.target.value) || 0; // Default to 0 if parseFloat fails
    let currentErrorMessage = "";

    setData((prevData) => {
      let updatedData = { ...prevData, [key]: value };

      if (key === "modulesPerDriver") {
        const numOfStrings = parseInt(selectedPart?.Strings || "0", 10);
        updatedData.numOfStrings = numOfStrings * value;
        const minCurrentRange =
          parseFloat(selectedPart["Min Range (A)"]) * value;
        const maxCurrentRange =
          parseFloat(selectedPart["Max Range (A)"]) * value;
        setCurrentRange({
          min: minCurrentRange.toFixed(2),
          max: maxCurrentRange.toFixed(2),
        });

        // Check if driver current is within the new range
        const currentDriverCurrent = parseFloat(prevData.driverCurrent || "0");
        if (
          currentDriverCurrent < minCurrentRange ||
          currentDriverCurrent > maxCurrentRange
        ) {
          currentErrorMessage = `Driver current is out of the range ${minCurrentRange.toFixed(
            2
          )}A - ${maxCurrentRange.toFixed(2)}A.`;
        } else {
          currentErrorMessage = ""; // Clear error message if current is within range
        }
      }

      if (key === "driverCurrent" || key === "numOfStrings") {
        const updatedCurrent = (
          updatedData.driverCurrent / updatedData.numOfStrings || 0
        ).toFixed(3);
        setSelectedCurrent(updatedCurrent);
      }

      console.log(`Updated data (${key}):`, updatedData);

      return updatedData;
    });

    setCurrentErrorMessage(currentErrorMessage);
  };

  const handlePercentageChange = (key) => (e) => {
    const value = parseFloat(e.target.value) || 0; // Default to 0 if parseFloat fails
    if (value >= 0 && value <= 100) {
      setData((prevData) => ({
        ...prevData,
        [key]: value / 100,
      }));
      setPercentErrorMessage("");
    } else {
      setPercentErrorMessage("Please enter a value between 0 and 100");
    }

    console.log(`Updated ${key}:`, value / 100);
  };

  const handleCriCctChange = (e) => {
    const [cri, cct] = e.target.value ? e.target.value.split(" / ") : ["", ""];
    setSelectedCRI(cri);
    setSelectedCCT(cct);

    // console.log("Selected CRI:", cri);
    // console.log("Selected CCT:", cct);
  };

  const handleTcChange = (e) => {
    setSelectedTc(e.target.value);
    console.log("Selected Tc:", e.target.value);
  };

  if (!items.length) {
    return <div>Loading...</div>;
  }

  // Get unique CRI/CCT pairs
  const uniqueCriCct = [
    ...new Set(
      productDetails
        .filter((item) => item["CCT"] && item["CRI"])
        .map((item) => `${item["CRI"]} / ${item["CCT"]}`)
    ),
  ];

  // Get unique Tc values
  const uniqueTc = [
    ...new Set(items.map((item) => item["Tc"]).filter((tc) => tc)),
  ];

  // console.log("Selected CCT:", selectedCCT);
  // console.log("Selected CRI:", selectedCRI);
  // console.log("Selected Tc:", selectedTc);
  // console.log("Selected Current:", selectedCurrent);

  // Group items by their Type
  const groupedOptions = items.reduce((acc, item) => {
    const type = item.Type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(item);
    return acc;
  }, {});

  const dropdownOptions = Object.entries(groupedOptions).map(
    ([label, options]) => ({
      label,
      options: options.map((item) => ({
        ...item,
        partNumber: item["Part Number"],
        range: `(${item["Min Range (A)"]}A - ${item["Max Range (A)"]}A)`,
      })),
    })
  );

  return (
    <div className="calculator">
      <table>
        <thead>
          <tr>
            <th colSpan="2">Module Inputs</th>{" "}
            {showCancelButton && (
              <button
                className="remove-calculator-button"
                onClick={() => onRemove(index)}
              >
                <CiCircleMinus />
              </button>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Part Number</td>
            <td>
              <CustomDropdown
                options={dropdownOptions}
                onChange={handlePartChange}
                value={selectedPart}
                labelKey="partNumber"
              />
            </td>
          </tr>
          <tr>
            <td>Module Description</td>
            <td>{selectedPart?.Description}</td>
          </tr>
          <tr>
            <td>CRI/CCT</td>
            <td>
              <select
                value={selectedCCT ? `${selectedCRI} / ${selectedCCT}` : ""}
                onChange={handleCriCctChange}
              >
                <option value="">Select CRI/CCT</option>
                {uniqueCriCct.map((criCct) => (
                  <option key={criCct} value={criCct}>
                    {criCct}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              T<sub>c</sub> (°C)
            </td>
            <td>
              <select value={selectedTc} onChange={handleTcChange}>
                <option value="">
                  Select T<sub>c</sub>
                </option>
                {uniqueTc.map((tc) => (
                  <option key={tc} value={tc}>
                    {tc}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>Modules (per driver)</td>
            <td>
              <input
                type="number"
                placeholder="Enter # of Modules"
                value={data.modulesPerDriver}
                onChange={handleDecimalChange("modulesPerDriver")}
              />
            </td>
          </tr>
          <tr>
            <td>Modules in Series</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Total Module Quantity</td>
            <td>{totalModuleQty}</td>
          </tr>
          <tr>
            <td>Number of Strings</td>
            <td>{data.numOfStrings}</td>
          </tr>
          <tr>
            <td>LEDs Per String</td>
            <td>{data.ledsPerString}</td>
          </tr>
          <tr>
            <td>Quantity of LEDs</td>
            <td>{qtyOfLeds}</td>
          </tr>
          <tr>
            <td>Driver Current (A)</td>
            <td>
              <input
                type="number"
                placeholder="Enter Driver Current"
                value={data.driverCurrent}
                onChange={handleDecimalChange("driverCurrent")}
                step={0.001}
              />
              &nbsp;
              <span className="current-range">
                {parseFloat(currentRange.min).toFixed(2)} -{" "}
                {parseFloat(currentRange.max).toFixed(2)}A
              </span>
            </td>
          </tr>
          <tr>
            <td>Current per LED (A)</td>
            <td>{selectedCurrent}</td>
          </tr>
          <tr>
            <td colSpan="2">
              {currentErrorMessage && (
                <div className="error-message">{currentErrorMessage}</div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th colSpan="2">Single Module Performance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Single LED Flux (Lm)</td>
            <td>{singleLedFlux.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Voltage per LED (V)</td>
            <td>{singleLedVoltage.toFixed(2)}</td>
          </tr>

          <tr>
            <td>Module Voltage (V)</td>
            <td>{moduleVoltage.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Module Current (A)</td>
            <td>{singleModuleCurrent.toFixed(3)}</td>
          </tr>
          <tr>
            <td>Module Power (W)</td>
            <td>{modulePower.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Module Lumens (Lm)</td>
            <td>{moduleLumens.toFixed(1)}</td>
          </tr>

          <tr>
            <td>Module Efficiency (Lm/W)</td>
            <td>{moduleEfficiency.toFixed(0)}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th colSpan="2">
              System Performance <br></br>(After Power/Optical Losses)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Voltage (V)</td>
            <td>{(1 * moduleVoltage).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Current (A)</td>
            <td>{data.driverCurrent ? data.driverCurrent.toFixed(3) : ""}</td>
          </tr>
          <tr>
            <td>Driver Efficiency (%)</td>
            <td>
              <input
                type="number"
                placeholder="Enter Driver Efficiency"
                value={(data.driverEfficiency * 100).toFixed(0)}
                onChange={handlePercentageChange("driverEfficiency")}
              />
            </td>
          </tr>
          <tr>
            <td>Optical Efficiency (%)</td>
            <td>
              <input
                type="number"
                placeholder="Enter Optical Efficiency"
                value={(data.opticalEfficiency * 100).toFixed(0)}
                onChange={handlePercentageChange("opticalEfficiency")}
              />
            </td>
          </tr>
          <tr>
            <td>Input Supply Power (W)</td>
            <td>{(modulePower / data.driverEfficiency).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Output Supply Power (W)</td>
            <td>{modulePower.toFixed(2)}</td>
          </tr>

          <tr>
            <td>Total Estimated System Lumens (Lm)</td>
            <td>{(moduleLumens * data.opticalEfficiency).toFixed(1)}</td>
          </tr>
          <tr>
            <td>Total Estimated System Efficiency (Lm/W)</td>
            <td>
              {(
                (totalModuleQty * moduleLumens * data.opticalEfficiency) /
                ((totalModuleQty * modulePower) / data.driverEfficiency)
              ).toFixed(0)}
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              {percentErrorMessage && (
                <div className="error-message">{percentErrorMessage}</div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ModuleCalculator;
