import React, { useState, useEffect, useCallback } from "react";
import Card from "./Card";
import "./Card.css";

const EnvironmentStep = ({ onNext, updateFormData, selectedEnvironment }) => {
  const [localSelectedEnvironment, setLocalSelectedEnvironment] =
    useState(selectedEnvironment);
  const [environments, setEnvironments] = useState([]);

  useEffect(() => {
    setLocalSelectedEnvironment(selectedEnvironment);
  }, [selectedEnvironment]);

  const handleNext = useCallback(() => {
    if (localSelectedEnvironment) {
      const selectedEnv = environments.find(
        (env) => env.name === localSelectedEnvironment
      );
      updateFormData("environment", selectedEnv);
      onNext();
    } else {
      alert("Please select an environment before proceeding.");
    }
  }, [localSelectedEnvironment, environments, onNext, updateFormData]);

  useEffect(() => {
    if (localSelectedEnvironment) {
      handleNext();
    }
  }, [localSelectedEnvironment, handleNext]);

  useEffect(() => {
    const allEnvironments = [
      {
        id: 1,
        name: "Office / Retail",
        description: "Suitable for office and retail environments.",
        image:
          "https://media.istockphoto.com/id/527910772/photo/ceiling-lighting-and-exhaust-louver.webp?b=1&s=170667a&w=0&k=20&c=KKUGs7tkGrK_-kO2-n9JELpeYnUsq43alN6ThL8MOpM=",
      },
      {
        id: 2,
        name: "Industrial",
        description: "Suitable for industrial environments.",
        image:
          "https://media.istockphoto.com/id/1344833299/photo/ceiling-with-bright-lights-in-a-modern-warehouse-shopping-center-building-office-or-other.webp?b=1&s=170667a&w=0&k=20&c=Z7NTwUmpazZoy0DPNE4ZL02RI6ZnxuABcvnY0u1Q54E=",
      },
      {
        id: 3,
        name: "Outdoor",
        description: "Suitable for outdoor environments.",
        image:
          "https://media.istockphoto.com/id/1162481048/photo/big-modern-empty-parking-lot-with-bright-led-street-lights-at-night.webp?b=1&s=170667a&w=0&k=20&c=4yXW72yF9gtex61p2PT9weLI37Xfr8t8Yo2IXtzoizM=",
      },
      {
        id: 4,
        name: "Harsh Environments",
        description: "Suitable for harsh environments.",
        image:
          "https://www.agcled.com/static/case/2/1/120W-high-bay-replace-400W-HID-in-workshop.jpeg",
      },
      {
        id: 5,
        name: "Architectural",
        description: "Suitable for architectural environments.",
        image:
          "https://media.istockphoto.com/id/522601713/photo/architecture-modern-design-house-outdoor.webp?b=1&s=170667a&w=0&k=20&c=UupDBRQ1pDircqOAIH129wAhuPsHtA8Z0oHSo5tFkTQ=",
      },
    ];
    setEnvironments(allEnvironments);
  }, []);
  const handleCardClick = (environment) => {
    setLocalSelectedEnvironment(environment);
  };
  return (
    <div className="step-container">
      <h2 className="environment-title">Select Environment</h2>
      <div className="card-container">
        {environments.map((environment) => (
          <Card
            key={environment.id}
            name={environment.name}
            description={environment.description}
            image={environment.image || "https://via.placeholder.com/150"}
            isSelected={environment.name === localSelectedEnvironment}
            onClick={() => handleCardClick(environment.name)}
            noHover
          />
        ))}
      </div>
    </div>
  );
};

export default EnvironmentStep;
