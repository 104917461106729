import React, { useState } from "react";
import { login, resetPassword } from "../../auth";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [error, setError] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [resetMessage, setResetMessage] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setResetMessage("");
    try {
      const user = await login(email, password);
      console.log("Logged in successfully", user);
      navigate("/create"); // Redirect to the desired page after login
    } catch (error) {
      if (error.message.includes("verify your email")) {
        setError("Please verify your email before logging in.");
      } else {
        setError("Incorrect email and/or password. Please try again.");
      }
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setResetMessage("Password reset email sent!");
    } catch (error) {
      setError("Error sending password reset email. Please try again.");
    }
  };

  //function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };
  return (
    <div className="auth-container">
      <h2 className="auth-container-title">
        {isReset ? "Reset Password" : "Login"}
      </h2>

      <form
        className="signup-form"
        onSubmit={isReset ? handlePasswordReset : handleLogin}
      >
        <div className="email-input-wrapper">
          <label>
            Email
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>

        {!isReset && (
          <div className="password-container">
            <div className="password-label-wrapper">
              <label className="password-label">Password</label>
              <span
                className="forgot-password-link"
                onClick={() => setIsReset(true)}
              >
                Forgot password?
              </span>
            </div>
            <div className="password-input-wrapper">
              {" "}
              <input
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={togglePasswordVisibility}
                className="password-visibility-button"
              >
                {passwordVisibility ? <MdVisibilityOff /> : <MdVisibility />}
              </span>
            </div>
          </div>
        )}

        {error && <div className="error-message">{error}</div>}
        {resetMessage && <div className="reset-message">{resetMessage}</div>}

        <button type="submit">
          {isReset ? "Send recovery email" : "Login"}
        </button>

        {isReset && (
          <span
            className="forgot-password-link"
            onClick={() => setIsReset(false)}
          >
            Back to Login
          </span>
        )}

        <div className="login-redirect">
          Don't have an account? <a href="/create-account">Sign up</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
