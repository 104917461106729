import React from "react";
import { useNavigate } from "react-router-dom";
import "./BrowseCollection.css"; // Add styles for BrowseCollection

const BrowseCollection = () => {
  const navigate = useNavigate();

  const categories = [
    { name: "modules", image: "/images/modules.webp" },
    { name: "drivers", image: "/images/drivers.webp" },
    { name: "heatsinks", image: "/images/heatsinks.webp" },
    { name: "lens", image: "/images/lens.webp" },
    { name: "connectors", image: "/images/connectors.webp" },
  ];

  return (
    <div className="collection-container">
      <h1 className="collection-header">Browse Collection</h1>
      <div className="category-cards">
        {categories.map((category) => (
          <div
            key={category.name}
            className="category-card"
            onClick={() => navigate(`/category/${category.name}`)}
          >
            <img
              src={category.image}
              alt={category.name}
              className="category-image"
            />
            <div className="category-name">
              {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrowseCollection;
