import React, { useState } from "react";
import ModuleCalculator from "./ModuleCalculator";
import "./MultiCalculatorView.css"; // Add the necessary CSS styles here
import { FaPlus, FaFileExcel } from "react-icons/fa";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const MultiCalculatorView = () => {
  const [calculators, setCalculators] = useState([{}]);

  const handleAddCalculator = () => {
    if (calculators.length < 4) {
      setCalculators([...calculators, {}]);
    }
  };

  const handleCalculatorUpdate = (index, results) => {
    // console.log(`Updating calculator at index ${index} with results:`, results); // Debugging line

    const updatedCalculators = calculators.map((calculator, i) =>
      i === index ? results : calculator
    );

    // console.log("Updated calculators state:", updatedCalculators); // Debugging line

    setCalculators(updatedCalculators);
  };

  const removeCalculator = (index) => {
    setCalculators(calculators.filter((_, i) => i !== index));
  };

  const generateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Module Calculator Results");

    // Define styles for headers and sections
    const headerStyle = {
      font: { bold: true, color: { argb: "FFFFFFFF" } },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFB51F25" },
      },
      alignment: { vertical: "middle", horizontal: "center" },
    };

    const sectionHeaderStyle = {
      font: { bold: true, color: { argb: "FFFFFFFF" } },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF969696" },
      },
      alignment: { vertical: "middle", horizontal: "center" },
    };

    const subHeaderStyle = {
      font: { bold: true, color: { argb: "FF28A745" } }, // Green color for subheader text
      alignment: { vertical: "middle", horizontal: "left" },
    };

    // Alternating row styles
    const evenRowStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" },
      },
    };

    const oddRowStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" },
      },
    };

    // Column Headers
    const headers = calculators.map((_, index) => `Calculator #${index + 1}`);
    sheet.addRow(["", ...headers]);
    sheet.getRow(1).eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Function to add a section with headers and data
    const addSection = (sectionTitle, fields) => {
      const sectionRow = sheet.addRow([sectionTitle]);
      sheet.mergeCells(
        sectionRow.number,
        1,
        sectionRow.number,
        headers.length + 1
      );
      sectionRow.eachCell((cell) => {
        cell.style = sectionHeaderStyle;
      });

      fields.forEach((field, rowIndex) => {
        const rowValues = [
          field.label,
          ...calculators.map((calc) => {
            const value = field.value(calc);
            console.log(`${field.label} for calculator ${rowIndex}:`, value); // Debugging output
            return value;
          }),
        ];
        const row = sheet.addRow(rowValues);

        // Apply alternating row styles
        const rowStyle = rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle;
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          cell.style = { ...cell.style, ...rowStyle };

          if (colNumber === 1) {
            // Apply subheader style to the first column
            cell.style = { ...cell.style, ...subHeaderStyle };
          }
        });
      });

      sheet.addRow([]); // Empty row for spacing
    };

    // Section 1: Module Performance Inputs
    addSection("Module Inputs", [
      {
        label: "Part Number",
        value: (calc) => calc.selectedPart?.partNumber || "",
      },
      {
        label: "Module Description",
        value: (calc) => calc.selectedPart?.Description || "",
      },
      {
        label: "CRI/CCT",
        value: (calc) =>
          calc.selectedCRI ? `${calc.selectedCRI} / ${calc.selectedCCT}` : "",
      },
      {
        label: "Tc (°C)",
        value: (calc) => calc.selectedTc || "",
      },
      {
        label: "Modules (per driver)",
        value: (calc) => calc.data?.modulesPerDriver || "",
      },
      {
        label: "Total Module Quantity",
        value: (calc) => calc.totalModuleQty || "",
      },
      {
        label: "Number of Strings",
        value: (calc) => calc.data?.numOfStrings || "",
      },
      {
        label: "LEDs Per String",
        value: (calc) => calc.data?.ledsPerString || "",
      },
      {
        label: "Quantity of LEDs",
        value: (calc) => {
          const qty = calc.data?.ledsPerString * calc.data?.numOfStrings || "0";
          console.log("Quantity of LEDs:", qty); // Debugging line
          return qty;
        },
      },
      {
        label: "Driver Current (A)",
        value: (calc) =>
          typeof calc.data?.driverCurrent === "number"
            ? calc.data.driverCurrent.toFixed(3)
            : "",
      },
      {
        label: "Current per LED (A)",
        value: (calc) => {
          const current = calc.selectedCurrent || "0"; // Default to 0 if current is not set
          console.log("LED Chip Current:", current); // Debugging line
          return current;
        },
      },
    ]);

    // Section 2: Module Performance
    addSection("Single Module Performance", [
      {
        label: "Single LED Flux (Lm)",
        value: (calc) =>
          typeof calc.singleLedFlux === "number"
            ? calc.singleLedFlux.toFixed(2)
            : "",
      },
      {
        label: "Voltage per LED (V)",
        value: (calc) =>
          typeof calc.singleLedVoltage === "number"
            ? calc.singleLedVoltage.toFixed(2)
            : "",
      },
      {
        label: "Module Voltage (V)",
        value: (calc) =>
          typeof calc.moduleVoltage === "number"
            ? calc.moduleVoltage.toFixed(2)
            : "",
      },

      {
        label: "Module Current (A)",
        value: (calc) =>
          typeof calc.singleModuleCurrent === "number"
            ? calc.singleModuleCurrent.toFixed(3)
            : "",
      },
      {
        label: "Module Power (W)",
        value: (calc) =>
          typeof calc.modulePower === "number"
            ? calc.modulePower.toFixed(2)
            : "",
      },
      {
        label: "Module Lumens (Lm)",
        value: (calc) =>
          typeof calc.moduleLumens === "number"
            ? calc.moduleLumens.toFixed(1)
            : "",
      },

      {
        label: "Module Efficiency (Lm/W)",
        value: (calc) =>
          typeof calc.moduleEfficiency === "number"
            ? calc.moduleEfficiency.toFixed(0)
            : "",
      },
    ]);

    // Section 3: Module Performance (with system losses)
    addSection("System Performance (After Power/Optical Losses)", [
      {
        label: "Voltage (V)",
        value: (calc) =>
          typeof calc.moduleVoltage === "number"
            ? calc.moduleVoltage.toFixed(2)
            : "",
      },
      {
        label: "Current (A)",
        value: (calc) =>
          typeof calc.data?.driverCurrent === "number"
            ? calc.data.driverCurrent.toFixed(3)
            : "",
      },
      {
        label: "Driver Efficiency (%)",
        value: (calc) =>
          typeof calc.data?.driverEfficiency === "number"
            ? (calc.data.driverEfficiency * 100).toFixed(0)
            : "",
      },
      {
        label: "Optical Efficiency (%)",
        value: (calc) =>
          typeof calc.data?.opticalEfficiency === "number"
            ? (calc.data.opticalEfficiency * 100).toFixed(0)
            : "",
      },
      {
        label: "Input Supply Power (W)",
        value: (calc) =>
          typeof calc.totalModuleQty === "number" &&
          typeof calc.modulePower === "number" &&
          typeof calc.data?.driverEfficiency === "number"
            ? (calc.modulePower / calc.data.driverEfficiency).toFixed(2)
            : "",
      },
      {
        label: "Output Supply Power (W)",
        value: (calc) =>
          typeof calc.totalModuleQty === "number" &&
          typeof calc.modulePower === "number"
            ? calc.modulePower.toFixed(2)
            : "",
      },

      {
        label: "Total Estimated System Lumens (Lm)",
        value: (calc) =>
          typeof calc.totalModuleQty === "number" &&
          typeof calc.moduleLumens === "number" &&
          typeof calc.data?.opticalEfficiency === "number"
            ? (calc.moduleLumens * calc.data.opticalEfficiency).toFixed(1)
            : "",
      },
      {
        label: "Total Estimated System Efficiency (Lm/W)",
        value: (calc) =>
          typeof calc.totalModuleQty === "number" &&
          typeof calc.moduleLumens === "number" &&
          typeof calc.modulePower === "number" &&
          typeof calc.data?.driverEfficiency === "number" &&
          typeof calc.data?.opticalEfficiency === "number"
            ? (
                (calc.moduleLumens * calc.data.opticalEfficiency) /
                (calc.modulePower / calc.data.driverEfficiency)
              ).toFixed(0)
            : "",
      },
    ]);

    // Set column widths
    sheet.columns = [{ width: 32 }, ...headers.map(() => ({ width: 20 }))];

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "module_calculator_results.xlsx");
    });
  };

  return (
    <div className="multi-calculator-view">
      <div className="combined-results-wrapper">
        <h1 className="calculator-heading">Module Calculator</h1>

        <div className="calculator-buttons">
          <button
            className="add-calculator-button"
            onClick={handleAddCalculator}
            disabled={calculators.length >= 4}
          >
            <FaPlus className="add-calculator-icon" />
            &ensp;Add Calculator
          </button>
          <button
            onClick={() => generateExcel(calculators)}
            className="download-button"
          >
            <FaFileExcel className="download-icon" />
            &ensp;Export to Excel
          </button>
        </div>
      </div>
      <div className="calculators-container">
        {calculators.map((_, index) => (
          <ModuleCalculator
            key={index}
            index={index}
            onUpdate={handleCalculatorUpdate}
            onRemove={removeCalculator}
            showCancelButton={calculators.length > 1}
          />
        ))}
      </div>
    </div>
  );
};

export default MultiCalculatorView;
