import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Adura. All rights reserved.</p>
        <div className="footer-links">
          <a href="https://adurasolutions.com/privacy/">Privacy Policy</a>
          <a href="https://adurasolutions.com/warranty/">Warranty Policy</a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
