// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  background: #f1f1f1;
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 0.385em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  margin-top: -0.4em;
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-left: 2px solid #1f1f1f;
  border-bottom: 2px solid #1f1f1f;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-group-label {
  padding: 10px;
  background: #fff;
  color: #999;
  font-family: "Fivo Sans Regular", sans-serif;
}

.dropdown-option {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-option:hover {
  background: #f1f1f1;
}

.option-label {
  flex-grow: 1;
}

.option-range {
  color: gray;
  font-size: 0.8em;
  white-space: nowrap;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModuleCalculator/CustomDropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".custom-dropdown {\n  position: relative;\n  width: 100%;\n}\n\n.dropdown-header {\n  background: #f1f1f1;\n  border: 1px solid #d8d8d8;\n  padding: 10px;\n  border-radius: 0.385em;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.dropdown-arrow {\n  margin-top: -0.4em;\n  display: inline-block;\n  width: 0.4em;\n  height: 0.4em;\n  border-left: 2px solid #1f1f1f;\n  border-bottom: 2px solid #1f1f1f;\n  transform: rotate(-45deg);\n  transition: transform 0.3s ease;\n}\n\n.dropdown-list {\n  position: absolute;\n  width: 100%;\n  background: #fff;\n  border: 1px solid #ccc;\n  max-height: 200px;\n  overflow-y: auto;\n  z-index: 1;\n}\n\n.dropdown-group-label {\n  padding: 10px;\n  background: #fff;\n  color: #999;\n  font-family: \"Fivo Sans Regular\", sans-serif;\n}\n\n.dropdown-option {\n  padding: 10px;\n  display: flex;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.dropdown-option:hover {\n  background: #f1f1f1;\n}\n\n.option-label {\n  flex-grow: 1;\n}\n\n.option-range {\n  color: gray;\n  font-size: 0.8em;\n  white-space: nowrap;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
