import React, { useState, useEffect, useRef } from "react";
import { FaTimes, FaSave } from "react-icons/fa";
import "./EditModal.css";
import MultiSelectDropdown from "./MultiSelectDropdown";

const EditModal = ({ item, onSave, onClose, lensOptions, category }) => {
  const [editedItem, setEditedItem] = useState(item);
  const modalRef = useRef(null);

  useEffect(() => {
    setEditedItem(item);
    console.log("Editing item in modal:", item); // Log the item being edited in the modal

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [item, onClose]);

  const handleChange = (key, value) => {
    setEditedItem({ ...editedItem, [key]: value });
  };

  const handleSpecsChange = (key, value) => {
    setEditedItem({
      ...editedItem,
      specs: { ...editedItem.specs, [key]: value },
    });
  };

  const handleSave = () => {
    console.log("Saving edited item:", editedItem); // Log the item being saved
    onSave(editedItem);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2 className="modal-header-title">Edit Item</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <label>
          Part Name:
          <input
            type="text"
            value={editedItem.part_name}
            onChange={(e) => handleChange("part_name", e.target.value)}
            className="modal-input"
          />
        </label>
        <label>
          Image URL:
          <input
            type="text"
            value={editedItem.image}
            onChange={(e) => handleChange("image", e.target.value)}
            className="modal-input"
          />
        </label>
        <label>
          Link:
          <input
            type="text"
            value={editedItem.link}
            onChange={(e) => handleChange("link", e.target.value)}
            className="modal-input"
          />
        </label>
        {category === "modules" && (
          <label>
            Lenses Options:
            <MultiSelectDropdown
              options={lensOptions}
              value={
                editedItem.specs["Lenses Options"] &&
                editedItem.specs["Lenses Options"] !== "None"
                  ? editedItem.specs["Lenses Options"].split(", ")
                  : []
              }
              onChange={(selected) =>
                handleSpecsChange(
                  "Lenses Options",
                  selected.length > 0 ? selected.join(", ") : "None"
                )
              }
            />
          </label>
        )}
        {Object.keys(editedItem.specs || {}).map(
          (key) =>
            key !== "Lenses Options" && (
              <label key={key}>
                {key}:
                <input
                  type="text"
                  value={editedItem.specs[key]}
                  onChange={(e) => handleSpecsChange(key, e.target.value)}
                  className="modal-input"
                />
              </label>
            )
        )}
        <div className="modal-actions">
          <button onClick={handleSave} className="save-button">
            <FaSave />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
