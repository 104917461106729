// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomContextMenu.css */
.custom-context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 150px;
}

.custom-context-menu-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  font-size: 1em;
  font-family: "Fivo Sans Regular", sans-serif;
  color: #666;
}

.custom-context-menu-item:hover {
  background-color: #f0f0f0;
}

.custom-context-menu-item svg {
  margin-right: 10px;
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/YourCreations/CustomContextMenu.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,4CAA4C;EAC5C,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["/* CustomContextMenu.css */\n.custom-context-menu {\n  position: absolute;\n  background-color: white;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);\n  z-index: 1000;\n  min-width: 150px;\n}\n\n.custom-context-menu-item {\n  padding: 10px 20px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n  display: flex;\n  align-items: center;\n  font-size: 1em;\n  font-family: \"Fivo Sans Regular\", sans-serif;\n  color: #666;\n}\n\n.custom-context-menu-item:hover {\n  background-color: #f0f0f0;\n}\n\n.custom-context-menu-item svg {\n  margin-right: 10px;\n  font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
